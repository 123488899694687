import { createAction } from 'redux-actions';

// Types
const CLEAR_ERROR = 'video/CLEAR_ERROR';
const SET_ERROR = 'video/SET_ERROR';

// Actions
export const clearErrorAction = createAction(CLEAR_ERROR);
export const setErrorAction = createAction(SET_ERROR);

// Reducers
const INITIAL_STATE = false;

export const errorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_ERROR:
      return false;

    case SET_ERROR:
      return action.payload;

    default:
      return state;
  }
};
