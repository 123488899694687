import PropTypes from 'prop-types';

import author from './author';
import image from './image';
import organization from './organization';
import slide from './slide';
import taxonomy from './taxonomy';
import { videoProps } from './video';

export default PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  item: PropTypes.shape({
    /* Type-specific parameters */
    // Video
    ...videoProps,

    // Article
    taxonomy,
    authors: PropTypes.arrayOf(author),
    source: PropTypes.shape({
      organization,
    }),

    // Slideshow
    slides: PropTypes.arrayOf(slide),

    /* Shared parameters */
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
    dateCreated: PropTypes.string,
    breakingNews: PropTypes.bool,
    nativeAd: PropTypes.bool,
    urls: PropTypes.shape({
      canonical: PropTypes.string,
      slug: PropTypes.string,
    }),
  }),
  computedValues: PropTypes.shape({
    teaseImage: image,
    headline: PropTypes.string,
    description: PropTypes.string,
    unibrow: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.shape({
        primary: PropTypes.string,
      }),
    }),
    url: PropTypes.string,
  }),
  metadata: PropTypes.objectOf(PropTypes.any),
  packageIndex: PropTypes.number,
});
