import api from 'lib/api';

const NEWSLETTER_LOAD_PENDING = 'newsletter/NEWSLETTER_LOAD_PENDING';
const NEWSLETTER_LOAD_COMPLETE = 'newsletter/NEWSLETTER_LOAD_COMPLETE';
const NEWSLETTER_LOAD_FAILED = 'newsletter/NEWSLETTER_LOAD_FAILED';

export const getNewsletterData = () => api({
  endpoint: 'newsletter',
  types: [NEWSLETTER_LOAD_PENDING, NEWSLETTER_LOAD_COMPLETE, NEWSLETTER_LOAD_FAILED],
  api: 'services',
});

// Reducer
const INITIAL_STATE = {
  newsletterLoading: false,
  newsletterLoaded: false,
  newsletterError: false,
  newsletterData: {},
};

export const newsletter = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case NEWSLETTER_LOAD_PENDING: {
      return {
        ...state,
        newsletterLoading: true,
      };
    }

    case NEWSLETTER_LOAD_COMPLETE: {
      return {
        ...state,
        newsletterLoading: false,
        newsletterLoaded: true,
        newsletterData: payload.data,
      };
    }

    case NEWSLETTER_LOAD_FAILED: {
      return {
        ...state,
        newsletterLoading: false,
        newsletterLoaded: true,
        newsletterError: true,
      };
    }

    default:
      return state;
  }
};
