import PropTypes from 'prop-types';

export default PropTypes.shape({
  badge: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  shortDescription: PropTypes.string,
  attribution: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  showEyebrow: PropTypes.bool,
  showDek: PropTypes.bool,
});
