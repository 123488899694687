import getNextConfig from 'next/config';
import React from 'react';
import { wrapper } from 'redux/createStore';
import { CookiesProvider, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import includes from 'lodash.includes';

import {
  disableLazyLoadAction,
  loadSocialMedia,
  setChromeless,
  setPlatform,
  setTaboolaFeed,
  setVertical,
  setView,
} from 'redux/modules/shared';
import { globalLoadComplete } from 'redux/modules/router';
import { setInitialTheme, setInitialNavbarLocal } from 'redux/modules/navbar';

import parseQueryString from 'lib/parseQuerystring';
import { isStartTodayUrl } from 'lib/startTodayUtils';
import {
  BreakpointContextProvider,
  RouteContext,
  VerticalContext,
  FeatureFlagContext,
} from 'lib/ContextTypes';
import { CoreVideoTokens } from 'lib/ContextTypes/coreVideoTokens';
import { UseGammaVod } from 'lib/ContextTypes/useGammaVod';
import { getRamenServicesUrl } from 'lib/getRamenServicesUrl';
import BTE from 'lib/BTE';
import { isEmbedPath } from 'lib/isEmbedPath';
import { preloadMarqueeEmbedContent } from 'lib/inlinePriorityEmbeds';
import { getDerivedHttpResponseStatusCodeFromPageProps } from 'lib/getDerivedHttpResponseStatusCodeFromPageProps';
import { initializeDatadogRum } from 'lib/datadog';
import { VIEW_LIST, VIEW } from 'lib/view';

import { AirshipWebNotifications } from 'components/AirshipWebNotifications';
import { useMyNewsStore } from 'store';

// Language configuration and initialization
import 'lib/Locale/i18n';
import { isBrowser } from 'lib/BrowserDetection';
import { AUTHENTICATED, UNAUTHENTICATED } from 'lib/myNewsConstants';
// eslint-disable-next-line import/no-unresolved
import '@nbcnews/omega-player/index.css';

const {
  publicRuntimeConfig: {
    dev,
    ENABLE_THEME_PALETTE,
  },
} = getNextConfig();

const VIDEO_TOKENS_CACHE_TTL = 60000;
const VIDEO_TOKENS_PATH = 'videoTokens';

let ThemingPalette;
if (process.env.NODE_ENV !== 'production' && ENABLE_THEME_PALETTE) {
  // eslint-disable-next-line global-require
  ThemingPalette = require('../src/components/ThemingPalette').default;
}

if (isBrowser() && !dev) {
  initializeDatadogRum();
}

const getCookies = (ctx) => {
  if (ctx?.req?.universalCookies) {
    return ctx.req.universalCookies;
  }
  return new Cookies();
};

if (process.env.PLAYWRIGHT_TEST && typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  const { worker } = require('../src/mocks/browser');
  worker.start();
}

function App(props) {
  const setAuthentication = useMyNewsStore((state) => state.setAuthentication);
  const getCustomer = useMyNewsStore((state) => state.getCustomer);
  const resetAuthentication = useMyNewsStore((state) => state.resetAuthentication);
  const customerLoadedRef = React.useRef();

  React.useEffect(() => {
    BTE.monitorScroll();
    BTE.monitorResize();

    // Listen to HFS.identity.state event
    const hfsHeader = document.getElementById('hfs-header');

    async function handleIdentityStateEvent(event = {}) {
      // Set authenticationState in store
      const { state } = event.detail || {};

      if (state === AUTHENTICATED && !customerLoadedRef.current) {
        customerLoadedRef.current = true;
        // Set authenticationState from Identity SDK in state.
        setAuthentication(state);
        // Get customer data from mynewsapi
        getCustomer();
      }

      if (state === UNAUTHENTICATED) {
        customerLoadedRef.current = false;
        resetAuthentication();
      }
    }
    hfsHeader?.addEventListener('HFS.identity.state', handleIdentityStateEvent);

    return () => {
      BTE.deregisterScroll();
      BTE.deregisterResize();
      hfsHeader?.removeEventListener('HFS.identity.state', handleIdentityStateEvent);
    };
  }, []);

  const {
    airshipWebNotificationsEnabled,
    Component,
    cookies,
    featureFlagQueryParam,
    useGammaVod,
    videoTokens,
    pageProps,
    launchDarklyFlags,
  } = props;

  const {
    domain,
    hostname,
    path,
    statusCode,
    vertical,
  } = pageProps;

  const airshipNotificationsEnabled = !isEmbedPath(path) && airshipWebNotificationsEnabled;

  return (
    <CookiesProvider cookies={isBrowser() ? undefined : cookies}>
      <VerticalContext.Provider value={vertical}>
        <FeatureFlagContext.Provider value={{ ...launchDarklyFlags, featureFlagQueryParam }}>
          <RouteContext.Provider
            value={{
              domain, hostname, path, statusCode,
            }}
          >
            <BreakpointContextProvider>
              <CoreVideoTokens.Provider value={videoTokens}>
                <UseGammaVod.Provider value={useGammaVod}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <Component {...pageProps} />
                </UseGammaVod.Provider>
              </CoreVideoTokens.Provider>
              {
                ThemingPalette && <ThemingPalette vertical={vertical} />
              }
              {airshipNotificationsEnabled ? (
                <AirshipWebNotifications />
              ) : null}
            </BreakpointContextProvider>
          </RouteContext.Provider>
        </FeatureFlagContext.Provider>
      </VerticalContext.Provider>
    </CookiesProvider>
  );
}

App.propTypes = {
  airshipWebNotificationsEnabled: PropTypes.bool.isRequired,
  Component: PropTypes.elementType.isRequired,
  cookies: PropTypes.shape({}),
  featureFlagQueryParam: PropTypes.bool,
  useGammaVod: PropTypes.bool,
  videoTokens: PropTypes.shape({
    timestamp: PropTypes.number.isRequired,
    tokenByDrmType: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
  launchDarklyFlags: PropTypes.shape({}).isRequired,
  pageProps: PropTypes.shape({
    domain: PropTypes.string.isRequired,
    hostname: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    statusCode: PropTypes.number.isRequired,
    vertical: PropTypes.string.isRequired,
  }).isRequired,
};

App.defaultProps = {
  cookies: {},
  featureFlagQueryParam: false,
  useGammaVod: undefined,
};

App.getInitialProps = wrapper.getInitialAppProps(
  (store) => async (context) => {
    const {
      Component: PageComponent,
      ctx,
      ctx: {
        asPath,
        query: { page },
        req: { url },
        res: {
          locals: {
            cacheRequest,
            domain,
            getLaunchDarklyFlag,
            hostname,
            launchDarklyFlags,
            layout,
            vertical,
            fullUrl,
            logger,
          } = {},
          statusCode,
        },
      },
    } = context;

    await store.dispatch(setVertical(vertical));

    /**
     * Fetch Launch Darkly feature flag by name while passing custom LDUser data
     * @param {string} flagName Name of the LD feature flag to retrieve
     * @param {object} customParams Custom data to pass to LD feature flag
     * @returns {boolean}
     */
    const getLaunchDarklyFlagWithCustomParams = ({ flagName = null, customParams = {} }) => (
      (!getLaunchDarklyFlag || !flagName)
        ? false
        : getLaunchDarklyFlag(flagName, (getLDUser) => getLDUser({
          custom: {
            pageType: page,
            vertical,
            fullUrl,
            ...customParams,
          },
        }))
    );

    const getAirshipWebNotifications = getLaunchDarklyFlagWithCustomParams({
      flagName: 'airship-web-notifications',
    });

    const getVideoTokens = cacheRequest({
      url: `${getRamenServicesUrl()}/${VIDEO_TOKENS_PATH}`,
      ttlms: VIDEO_TOKENS_CACHE_TTL,
      cacheKey: ':VideoTokens:',
    }).catch((error) => {
      logger.error(error);
    });

    // need to add `store` to the context passed to each page component's `getInitialProps`. that
    // way the page component doesn't need to use `next-redux-wrapper`'s `getInitialPageProps` which
    // adds the store to the resulting props, thereby duplicating the store in the `__NEXT_DATA__`
    // in the markup sent to the client.
    const pageContext = { ...ctx, store };
    // pageProps is used in the render to provide props to the page component
    let pageProps = {};
    if (PageComponent.getInitialProps) {
      pageProps = await PageComponent.getInitialProps(pageContext);

      // Component.WrappedComponent needed to bypass redux connect wrapper
    } else if (PageComponent.WrappedComponent?.getInitialProps) {
      pageProps = await PageComponent.WrappedComponent.getInitialProps(pageContext);
    }

    const pagePropsStatusCode = getDerivedHttpResponseStatusCodeFromPageProps(pageProps);
    if (pagePropsStatusCode >= 400) {
      context.ctx.res.statusCode = pagePropsStatusCode;
    }

    const featureFlagParam = context?.query?.featureFlag ?? false;
    // `featureFlagQueryParam` context should be used only for the Akamai-
    // added `featureFlag` query string param.
    // See this doc: https://nbcnewsdigital.atlassian.net/wiki/spaces/RAM/pages/2639953981/A+B+Tests
    const featureFlagQueryParam = !includes(featureFlagParam, 'false');

    const promises = [
      store.dispatch(setPlatform('ramen-nextjs')),
      store.dispatch(setInitialTheme(vertical)),
      store.dispatch(setInitialNavbarLocal({ vertical, featureFlagQueryParam })),
      store.dispatch(globalLoadComplete({
        domain,
        host: hostname,
        navigating: pageProps.navigating || false,
        path: asPath,
      })),
    ];

    // Query string params
    const {
      chromeless,
      lazyload,
      taboolafeed,
    } = (asPath && parseQueryString(asPath)) || {};

    // Set view to chromeless on Start today app path and Start Today App view
    const isStartTodayApp = isStartTodayUrl(url) || VIEW_LIST.includes(chromeless?.toLowerCase());
    if (isStartTodayApp) {
      promises.push(store.dispatch(setChromeless()));
      promises.push(store.dispatch(setView(VIEW.START_TODAY_APP)));

    // chromeless=true - enable chromeless render
    } else if (chromeless?.toLowerCase() === 'true') {
      promises.push(store.dispatch(setChromeless()));
    }

    // lazyload=false - disable lazyload of images/components
    //   This is used for automated visual regression testing
    if (lazyload === 'false') {
      promises.push(store.dispatch(disableLazyLoadAction()));
    }
    // What is this used for?
    if (taboolafeed && taboolafeed.toLowerCase() === 'true') {
      promises.push(store.dispatch(setTaboolaFeed()));
    }

    // Load social media profiles
    promises.push(store.dispatch(loadSocialMedia(vertical)));

    const layoutPromise = preloadMarqueeEmbedContent(layout);

    await Promise.all(promises);

    if (!pageProps.namespacesRequired) {
      pageProps.namespacesRequired = [];
    }

    return {
      airshipWebNotificationsEnabled: await getAirshipWebNotifications,
      cookies: getCookies(context.ctx),
      featureFlagQueryParam,
      launchDarklyFlags,
      videoTokens: await getVideoTokens,
      pageProps: {
        domain,
        hostname,
        host: hostname, // is this needed?
        layout: await layoutPromise,
        path: asPath,
        statusCode,
        vertical,
        ...pageProps,
      },
    };
  },
);

export default wrapper.withRedux(App);
