import { createAction } from 'redux-actions';

import { ARTICLE_LOAD_FAILED } from 'redux/modules/article';
import { RECIPE_LOAD_ERROR } from 'redux/modules/recipe';
import { FRONT_LOAD_FAILED } from 'redux/modules/front';

export const GLOBAL_LOAD_PENDING = 'router/GLOBAL_LOAD_PENDING';
export const GLOBAL_LOAD_COMPLETE = 'router/GLOBAL_LOAD_COMPLETE';
export const SET_STATUS_PAGE_NOT_FOUND = 'route/SET_STATUS_PAGE_NOT_FOUND';

export const globalLoadPending = createAction(GLOBAL_LOAD_PENDING);
export const globalLoadComplete = createAction(GLOBAL_LOAD_COMPLETE);
export const setPageNotFoundAction = createAction(SET_STATUS_PAGE_NOT_FOUND);


const INITIAL_STATE = {
  domain: null,
  error: null,
  host: null,
  loaded: false,
  navigating: false,
  path: '/',
  status: 200,
};

export const router = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case FRONT_LOAD_FAILED:
      return {
        ...state,
        status: payload.status,
      };

    case ARTICLE_LOAD_FAILED:
      return {
        ...state,
        status: payload.status,
      };

    case RECIPE_LOAD_ERROR:
      return {
        ...state,
        status: payload.status,
      };

    case GLOBAL_LOAD_PENDING:
      return {
        ...state,
        navigating: true,
      };

    case GLOBAL_LOAD_COMPLETE:
      return {
        ...state,
        navigating: false,
        path: payload.path,
        domain: payload.domain,
        status: payload.status || state.status,
        host: payload.host,
      };

    case SET_STATUS_PAGE_NOT_FOUND:
      return {
        ...state,
        status: 404,
      };

    default:
      return state;
  }
};
