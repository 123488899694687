import { createAction } from 'redux-actions';
import { apiFetchJsonp } from 'lib/apiFetchJsonp';

// Types
export const LOCAL_LOCATION_PENDING = 'localnews/LOCAL_LOCATION_PENDING';
export const LOCAL_LOCATION_SUCCESS = 'localnews/LOCAL_LOCATION_SUCCESS';
export const LOCAL_LOCATION_FAILED = 'localnews/LOCAL_LOCATION_FAILED';
export const LOCAL_NEWS_PENDING = 'localnews/LOCAL_NEWS_PENDING';
export const LOCAL_NEWS_SUCCESS = 'localnews/LOCAL_NEWS_SUCCESS';
export const LOCAL_NEWS_FAILED = 'localnews/LOCAL_NEWS_FAILED';
export const LOCAL_SET_LOCATION = 'localnews/LOCAL_SET_LOCATION';
export const LOCAL_WEATHER_PENDING = 'localnews/LOCAL_WEATHER_PENDING';
export const LOCAL_WEATHER_SUCCESS = 'localnews/LOCAL_WEATHER_SUCCESS';
export const LOCAL_WEATHER_ERROR = 'localnews/LOCAL_WEATHER_ERROR';
export const RESET_LOCAL_NEWS = 'localnews/RESET_LOCAL_NEWS';

export const loadLocationByIp = () => apiFetchJsonp({
  endpoint: 'https://geoip.nbcnews.com/api/ipinfo',
  types: [LOCAL_LOCATION_PENDING, LOCAL_LOCATION_SUCCESS, LOCAL_LOCATION_FAILED],
});

export const loadLocationByZip = (zip) => apiFetchJsonp({
  endpoint: `https://geoip.nbcnews.com/api/zipinfo?zip=${zip}`,
  types: [LOCAL_LOCATION_PENDING, LOCAL_LOCATION_SUCCESS, LOCAL_LOCATION_FAILED],
});

export const loadNews = (zip) => apiFetchJsonp({
  endpoint: `https://localnewsapi.nbcnews.com/news/${zip}`,
  types: [LOCAL_NEWS_PENDING, LOCAL_NEWS_SUCCESS, LOCAL_NEWS_FAILED],
});

export const loadLocalWeather = (zip) => apiFetchJsonp({
  endpoint: `https://localnewsapi.nbcnews.com/weather/e/${zip}`,
  types: [LOCAL_WEATHER_PENDING, LOCAL_WEATHER_SUCCESS, LOCAL_WEATHER_ERROR],
});

export const manuallySetLocation = createAction(LOCAL_SET_LOCATION);

// Reducer

const INITIAL_STATE = Object.freeze({
  news: [],
  zip: null,
  city: null,
  state: null,
  latitude: null,
  longitude: null,
  error: null,
  loading: false,
  weather: {
    error: null,
    hours: [],
    loading: false,
  },
});

export function localNews(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case RESET_LOCAL_NEWS:
      return {
        ...INITIAL_STATE,
      };

    case LOCAL_LOCATION_SUCCESS:
      return {
        ...state,
        zip: payload.city_data.postal_code,
        city: payload.city_data.city,
        state: payload.state_data.state_code,
        latitude: payload.location_data.latitude,
        longitude: payload.location_data.longitude,
        loading: false,
      };

    case LOCAL_LOCATION_PENDING:
      return {
        ...state,
        loading: true,
      };

    case LOCAL_LOCATION_FAILED:
      return {
        ...state,
        city: null,
        state: null,
        news: {
          error: 'Zip code not found.',
        },
        loading: false,
      };

    case LOCAL_NEWS_SUCCESS:
      return {
        ...state,
        news: payload.data,
        loading: false,
      };

    case LOCAL_NEWS_PENDING:
      return {
        ...state,
        loading: true,
      };

    case LOCAL_NEWS_FAILED:
      return {
        ...state,
        news: {
          error: payload,
        },
        loading: false,
      };

    case LOCAL_SET_LOCATION:
      return {
        ...state,
        ...payload,
      };

    case LOCAL_WEATHER_PENDING:
      return {
        ...state,
        weather: {
          ...state.weather,
          loading: true,
        },
      };

    case LOCAL_WEATHER_SUCCESS:
      return {
        ...state,
        weather: {
          ...payload,
          loading: false,
        },
      };

    case LOCAL_WEATHER_ERROR:
      return {
        ...state,
        weather: {
          error: payload,
          loading: false,
        },
      };

    default:
      return state;
  }
}
