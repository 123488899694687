/*
  *****WARNING*****

  User agent sniffing is an anti-pattern, you may want to explore other approaches
  before using code in this lib
  https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent

  Specifically to the functionality here, things like the Facebook in-app browser will
  return unexpected results. For instance, this was tested in the Facebook in-app browser
  on an iPhone running Safari v13 but the userAgent in the in-app browser didn't even
  show that Safari was being used

  *****WARNING*****
*/

const BrowserDetection = {
  hasUserAgent() {
    if (typeof navigator !== 'undefined') {
      return navigator.userAgent || ' ';
    }
    return false;
  },

  isSafari() {
    if (this.hasUserAgent()) {
      const userAgent = navigator.userAgent.toLowerCase();
      return userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1;
    }
    return false;
  },

  isChrome() {
    if (this.hasUserAgent()) {
      const userAgent = navigator.userAgent.toLowerCase();
      return !!window.chrome && userAgent.indexOf('chrome') !== -1;
    }
    return false;
  },

  isIE() {
    if (this.hasUserAgent()) {
      const userAgent = navigator.userAgent.toLowerCase();
      // MSIE used to detect old browsers and Trident used to newer ones
      return userAgent.indexOf('msie ') > -1 || userAgent.indexOf('trident/') > -1;
    }
    return false;
  },

  isFirefox() {
    if (this.hasUserAgent()) {
      const userAgent = navigator.userAgent.toLowerCase();
      // MSIE used to detect old browsers and Trident used to newer ones
      return userAgent.includes('firefox');
    }
    return false;
  },

  /* eslint-disable max-len */
  version() {
    if (this.hasUserAgent()) {
      const userAgent = this.hasUserAgent();

      // Edge
      //   Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0
      //   Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586
      const edge = userAgent.indexOf('Edge/');
      if (edge > 0) {
        return parseInt(userAgent.substring(edge + 5, userAgent.indexOf('.', edge)), 10);
      }

      // Chrome
      //   Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/78.0.3904.108 Safari/537.36

      // Firefox
      //   Mozilla/5.0 (Macintosh; Intel Mac OS X 10.13; rv:60.0) Gecko/20100101 Firefox/60.0

      // Safari
      //   Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1 Safari/605.1.15
      const version = userAgent.match(/(?:version|chrome|firefox)\/(\d+)/i);
      if (version?.[1]) {
        return parseInt(version[1], 10);
      }

      // IE 10
      //   Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)
      const msie = userAgent.indexOf('MSIE ');
      if (msie > 0) {
        return parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10);
      }

      // IE 11
      //   Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko
      const trident = userAgent.indexOf('Trident/');
      if (trident > 0) {
        const rv = userAgent.indexOf('rv:');
        return parseInt(userAgent.substring(rv + 3, userAgent.indexOf('.', rv)), 10);
      }
    }

    return -1;
  },
  /* eslint-enable max-len */
};

/**
 * Determine if you're in a browser context
 * @see https://github.com/vercel/next.js/issues/2473#issuecomment-362119102
 * @returns {boolean}
 */
export const isBrowser = () => typeof window !== 'undefined';

export default BrowserDetection;
