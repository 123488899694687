import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  thumbnail: PropTypes.string,
  url: PropTypes.string,
});
