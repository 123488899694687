import PropTypes from 'prop-types';

export default {
  stylesOverride: PropTypes.string,
  svg: PropTypes.shape({ height: PropTypes.string, width: PropTypes.string }),
  pattern: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    patternTransform: PropTypes.string,
  }),
  line: PropTypes.shape({
    x1: PropTypes.string,
    y: PropTypes.string,
    x2: PropTypes.string,
    y2: PropTypes.string,
    stroke: PropTypes.string,
    strokeWidth: PropTypes.string,
  }),
  rect: PropTypes.shape({ height: PropTypes.string, width: PropTypes.string }),
};
