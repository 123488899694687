import api from 'lib/api';
import getDocumentTracingObj from 'lib/getDocumentTracingObj';

// Types
export const MENU_LOAD_PENDING = 'menu/MENU_LOAD_PENDING';
export const MENU_LOAD_SUCCESS = 'menu/MENU_LOAD_SUCCESS';
export const MENU_LOAD_ERROR = 'menu/MENU_LOAD_ERROR';

// Actions
export const loadMenu = (id) => api({
  endpoint: `menu/${id}`,
  types: [MENU_LOAD_PENDING, MENU_LOAD_SUCCESS, MENU_LOAD_ERROR],
});

// Reducer
export const INITIAL_STATE = {
  loading: false,
  current: null,
};

export function Menu(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MENU_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      };
    case MENU_LOAD_SUCCESS: {
      const { data: { menu }, extensions } = action.payload;
      return {
        ...state,
        loading: false,
        current: {
          ...menu,
          documentTracing: getDocumentTracingObj(menu, extensions),
        },
      };
    }
    case MENU_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
