import { datadogRum } from '@datadog/browser-rum';
import { getDatadogConfig } from './getDatadogConfig';

export function initializeDatadogRum() {
  const datadogConfig = getDatadogConfig();

  if (datadogConfig.applicationId) {
    datadogRum.init({
      ...getDatadogConfig(),
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      service: 'ramen-frontend',
      allowedTracingUrls: [window.location.origin],
      trackResources: true,
    });
  }
}

function log(callback) {
  try {
    // eslint-disable-next-line no-undef
    DD_RUM.onReady(() => callback(DD_RUM));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error attempting to log to Datadog:', e);
  }
}

export function logError(error, context) {
  log((rum) => rum.addError(error, context));
}

export function logAction(actionName, context) {
  log((rum) => rum.addAction(actionName, context));
}
