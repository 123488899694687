import PropTypes from 'prop-types';

export default PropTypes.shape({
  headline: PropTypes.shape({
    primary: PropTypes.string,
  }),
  description: PropTypes.shape({
    primary: PropTypes.string,
  }),
  ecommerceEnabled: PropTypes.bool,
  url: PropTypes.string,
});
