import PropTypes from 'prop-types';
import layout from './layout';

export default PropTypes.shape({
  id: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  pageRoute: PropTypes.string,
  hidden: PropTypes.bool,
  adsDisabled: PropTypes.bool,
  layouts: PropTypes.arrayOf(layout),
});
