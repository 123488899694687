import fetchJsonp from 'fetch-jsonp';
import { createAction } from 'redux-actions';

export const apiFetchJsonp = ({ endpoint, types }) => async (dispatch) => {
  const [PENDING, SUCCESS, ERROR] = types;
  const pending = createAction(PENDING);
  const success = createAction(SUCCESS);
  const error = createAction(ERROR);

  dispatch(pending());

  if (typeof window === 'object') {
    await fetchJsonp(endpoint)
      .then((response) => response.json())
      .then((body) => {
        dispatch(success(body));
      })
      .catch((e) => {
        dispatch(error(e));
      });
  }
};
