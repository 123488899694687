import PropTypes from 'prop-types';
import videoAsset from './videoAsset';
import image from './image';
import headline from './headline';
import url from './url';
import taxonomyCollection from './taxonomyCollection';
import description from './description';
import organization from './organization';

export const videoProps = {
  type: PropTypes.string,
  id: PropTypes.string,
  primaryImage: image,
  teaseImage: image,
  url,
  headline,
  description,
  nativeAd: PropTypes.bool,
  duration: PropTypes.string,
  mpxMetadata: PropTypes.shape({
    guid: PropTypes.string,
    mpxId: PropTypes.string,
  }),
  taxonomy: taxonomyCollection, // added
  source: organization,
  videoAssets: PropTypes.arrayOf(videoAsset),
};

export default PropTypes.shape(videoProps);
