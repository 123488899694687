import PropTypes from 'prop-types';

import url from './url';

export default PropTypes.shape({
  link: PropTypes.string,
  override: PropTypes.string,
  text: PropTypes.string,
  url,
});
