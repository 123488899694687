import PropTypes from 'prop-types';

const aggregationItem = PropTypes.shape({
  key: PropTypes.string,
  value: PropTypes.string,
  count: PropTypes.number,
});

export default PropTypes.shape({
  taxonomy: PropTypes.arrayOf(aggregationItem),
  priceList: PropTypes.arrayOf(aggregationItem),
  priceSale: PropTypes.arrayOf(aggregationItem),
});
