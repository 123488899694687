import getConfig from 'next/config';

export const DEFAULT_DD_RUM_APP_ID = '41db0d73-b61c-47a2-8cc7-22fc31d40948';
export const DEFAULT_DD_RUM_CLIENT_TOKEN = 'pub7f966390e40b86f9ca3ce812e183ad51';
export const DEFAULT_DD_ENV = 'dev';

/**
 * Returns the appId and clientToken for Datadog's Real User Metrics
 * @return {{applicationId: string, clientToken: string, env: string}} Object with appId and clientToken keys
 */
export function getDatadogConfig() {
  const {
    publicRuntimeConfig: {
      DD_RUM_APP_ID,
      DD_RUM_CLIENT_TOKEN,
      DD_RUM_SAMPLE_RATE,
      DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
      DD_ENV,
      appVersion,
    },
  } = getConfig();


  return {
    applicationId: DD_RUM_APP_ID || DEFAULT_DD_RUM_APP_ID,
    clientToken: DD_RUM_CLIENT_TOKEN || DEFAULT_DD_RUM_CLIENT_TOKEN,
    env: DD_ENV || DEFAULT_DD_ENV,
    version: appVersion,
    sessionSampleRate: DD_RUM_SAMPLE_RATE || 10,
    sessionReplaySampleRate: DD_RUM_SESSION_REPLAY_SAMPLE_RATE || 0,
  };
}
