import api from 'lib/api';

const type = 'showblog';

// Types
const SHOW_LEAD_LOAD_PENDING = `${type}/SHOW_LEAD_LOAD_PENDING`;
const SHOW_LEAD_LOAD_COMPLETE = `${type}/SHOW_LEAD_LOAD_COMPLETE`;
const SHOW_LEAD_LOAD_FAILED = `${type}/SHOW_LEAD_LOAD_FAILED`;

const NAV_LOAD_PENDING = `${type}/NAV_LOAD_PENDING`;
const NAV_LOAD_COMPLETE = `${type}/NAV_LOAD_COMPLETE`;
const NAV_LOAD_FAILED = `${type}/NAV_LOAD_FAILED`;

// Actions
export const loadShowLead = (vertical, route) => api({
  endpoint: `curation/slug/${vertical}/${route}`,
  types: [SHOW_LEAD_LOAD_PENDING, SHOW_LEAD_LOAD_COMPLETE, SHOW_LEAD_LOAD_FAILED],
});

export const loadPostNavigation = (path, dateCreated, excludedId) => api({
  endpoint: `showBlogNavigation/${excludedId}/${dateCreated}/${path}/`,
  types: [NAV_LOAD_PENDING, NAV_LOAD_COMPLETE, NAV_LOAD_FAILED],
});


// Reducer
export const INITIAL_STATE = {
  showLead: {},
  showLeadLoading: false,
  showLeadError: null,
  nav: {},
  navLoading: false,
  navError: null,

};

export function showBlog(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_LEAD_LOAD_PENDING:
      return {
        ...state,
        showLeadLoading: true,
        showLeadError: false,
      };

    case SHOW_LEAD_LOAD_COMPLETE: {
      const layouts = action?.payload?.data?.curation?.layouts ?? [];
      const packages = layouts?.[0]?.packages ?? [];
      const showLead = packages.find((p) => p.type === 'showLead');
      return {
        ...state,
        showLead,
        showLeadLoading: false,
        showLeadError: false,
      };
    }

    case SHOW_LEAD_LOAD_FAILED:
      return {
        ...state,
        showLeadLoading: false,
        showLeadError: action.payload,
      };

    case NAV_LOAD_PENDING:
      return {
        ...state,
        navLoading: true,
        navError: false,
      };

    case NAV_LOAD_COMPLETE: {
      const next = action?.payload?.data?.next?.items?.[0] ?? null;
      const previous = action?.payload?.data?.previous?.items?.[0] ?? null;
      const latest = action?.payload?.data?.latest?.items?.[0] ?? null;

      return {
        ...state,
        nav: {
          next,
          previous,
          latest,
        },
        navLoading: false,
        navError: false,
      };
    }

    case NAV_LOAD_FAILED:
      return {
        ...state,
        navLoading: false,
        navError: action.payload,
      };

    default:
      return state;
  }
}
