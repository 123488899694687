import PropTypes from 'prop-types';

import image from './image';

export default PropTypes.shape({
  headline: PropTypes.string,
  description: PropTypes.string,
  unibrow: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.shape({
      primary: PropTypes.string,
    }),
  }),
  url: PropTypes.string,
  // Single tease image object
  teaseImage: image,
  // Array of tease image objects with unique aspect ratios
  alternateTeaseImages: PropTypes.arrayOf(
    PropTypes.shape({
      aspectRatio: PropTypes.string,
      image,
    }),
  ),
});
