import PropTypes from 'prop-types';

export default PropTypes.oneOf([
  'article-body',
  'article-bottom',
  'article-sidebar',
  'article-top',
  'recipe-top',
  'recipe-bottom',
  'video-top',
  'footer',
  'header',
  'page-front',
]);
