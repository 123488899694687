import api from 'lib/api';

import { isTelemundoVertical } from 'lib/vertical';

import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';

// Import Types from shared file
import {
  LOAD_PENDING,
  LOAD_SUCCESS,
  LOAD_ERROR,
  SET_CURRENT,
  SET_AUTO_PLAY,
} from 'redux/modules/video/types';

// Reducers
import { associatedPlaylistsReducer as associatedPlaylists } from './associatedPlaylists';
import { autoPlayReducer as autoPlay } from './autoPlay';
import { currentReducer as current } from './current';
import { errorReducer as error } from './error';
import { relatedArticleReducer as relatedArticle } from './relatedArticle';
import { relatedVideoReducer as relatedVideo } from './relatedVideo';
import { topPlaylistsReducer as topPlaylists } from './topPlaylists';

// Actions
export const setCurrent = createAction(SET_CURRENT);
export const setAutoPlay = createAction(SET_AUTO_PLAY);

export const loadVideo = (id, vertical) => {
  let cleanId;

  if (isTelemundoVertical(vertical)) {
    cleanId = `tmvo${id.toString().replace(/^tmvo/, '')}`;
  } else {
    cleanId = `mmvo${id.toString().replace(/^mmvo/, '')}`;
  }

  return api({
    endpoint: `video/${cleanId}`,
    types: [LOAD_PENDING, LOAD_SUCCESS, LOAD_ERROR],
  });
};

// Composite Reducer
export const video = combineReducers({
  associatedPlaylists,
  autoPlay,
  current,
  error,
  relatedArticle,
  relatedVideo,
  topPlaylists,
});
