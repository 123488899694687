const en = require('./translations/en/translation.json');
const es = require('./translations/es/translation.json');

module.exports = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};
