import PropTypes from 'prop-types';

import url from './url';
import person from './person';
import organization from './organization';
import headline from './headline';

export default PropTypes.shape({
  id: PropTypes.string,
  headline,
  caption: PropTypes.string,
  altText: PropTypes.string,
  url,
  encodingFormat: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  authors: PropTypes.arrayOf(person),
  source: organization,
  graphicContent: PropTypes.bool,
});
