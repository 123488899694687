import PropTypes from 'prop-types';

import video from './video';
import headline from './headline';

export default PropTypes.shape({
  id: PropTypes.string,
  headline,
  videos: PropTypes.arrayOf(video),
});
