/*
  this will be deleted once curation and fronts are finished
*/

import PropTypes from 'prop-types';
import legacyImage from './legacyImage';
import headline from './headline';

export default PropTypes.shape({
  headline,
  byline: PropTypes.shape({
    name: PropTypes.string,
  }),
  source: PropTypes.shape({
    name: PropTypes.string,
    copyright: PropTypes.string,
  }),
  caption: PropTypes.string,
  image: legacyImage,
  isGraphic: PropTypes.bool,
});
