export const USA = 'US';
export const AUTHENTICATED = 'authenticated';
export const UNAUTHENTICATED = 'unauthenticated';
export const INITIALIZED = 'initialized';
export const UNINITIALIZED = 'uninitialized';
export const UNKNOWN = 'unknown';
export const GEOLOCATION_COOKIE = 'ng_geolocation';
export const NEWS = 'news';
export const NBCNEWS = 'nbcnews';
export const ALL = 'all';
export const ARTICLE = 'article';
export const VIDEO = 'video';
export const RECIPE = 'recipe';
export const PRODUCT = 'product';
export const AMOUNT_TO_DISPLAY_NEWS = 20;
export const AMOUNT_TO_DISPLAY_TODAY = 24;
export const AMOUNT_TO_SHOW = 10;
export const AMOUNT_TO_LOAD = 5;
export const RECOMMENDATIONS_AMOUNT = 30;

export const CONTENT_TYPES_TO_ROUTES = {
  article: 'articles',
  video: 'videos',
  recipe: 'recipes',
  product: 'wishlist',
  all: '',
};

// Create reverse map of CONTENT_TYPES_TO_ROUTES
export const ROUTES_TO_CONTENT_TYPES = Object.entries(CONTENT_TYPES_TO_ROUTES).reduce(
  (acc, [value, key]) => {
    acc[key] = value;
    return acc;
  },
  {},
);
