export const BREAKPOINTS = {
  s: 0,
  m: 758,
  l: 1000,
  xl: 1240,
  xxl: 1440,
  xxxl: 1600,
};

let smallMQL = null;
let mediumMQL = null;
let largeMQL = null;
let xLargeMQL = null;
let xxLargeMQL = null;
let xxxLargeMQL = null;

function onClient(fn, def = false) {
  return (...args) => (
    typeof window === 'undefined' ? def : fn(...args)
  );
}

const getMQL = onClient(
  (query) => window.matchMedia(query),
  { // mock matchMedia return
    matches: false,
    media: '',
    onchange: null,
    addListener: () => {},
    removeListener: () => {},
  },
);

const Breakpoints = {
  getSmallMQL() {
    if (!smallMQL) {
      smallMQL = getMQL(`(max-width: ${BREAKPOINTS.m - 1}px)`);
    }
    return smallMQL;
  },

  getMediumMQL() {
    if (!mediumMQL) {
      mediumMQL = getMQL(
        `(min-width: ${BREAKPOINTS.m}px) and (max-width: ${BREAKPOINTS.l - 1}px)`,
      );
    }
    return mediumMQL;
  },

  getLargeMQL() {
    if (!largeMQL) {
      largeMQL = getMQL(
        `(min-width: ${BREAKPOINTS.l}px) and (max-width: ${BREAKPOINTS.xl - 1}px)`,
      );
    }
    return largeMQL;
  },

  getXLargeMQL() {
    if (!xLargeMQL) {
      xLargeMQL = getMQL(`(min-width: ${BREAKPOINTS.xl}px)`);
    }
    return xLargeMQL;
  },

  getXXLargeMQL() {
    if (!xxLargeMQL) {
      xxLargeMQL = getMQL(`(min-width: ${BREAKPOINTS.xxl}px)`);
    }
    return xxLargeMQL;
  },
  getXXXLargeMQL() {
    if (!xxxLargeMQL) {
      xxxLargeMQL = getMQL(`(min-width: ${BREAKPOINTS.xxxl}px)`);
    }
    return xxxLargeMQL;
  },

  isS: onClient(() => Breakpoints.getSmallMQL().matches),

  isM: onClient(() => Breakpoints.getMediumMQL().matches),

  isL: onClient(() => Breakpoints.getLargeMQL().matches),

  isXL: onClient(() => Breakpoints.getXLargeMQL().matches),

  isXXL: onClient(() => Breakpoints.getXXLargeMQL().matches),

  isXXXL: onClient(() => Breakpoints.getXXXLargeMQL().matches),

  isSorM: onClient(() => Breakpoints.getSmallMQL().matches || Breakpoints.getMediumMQL().matches),

  isLorXL: onClient(() => Breakpoints.getLargeMQL().matches || Breakpoints.getXLargeMQL().matches),

  /**
   * Attach a listener function to all match media queries
   */
  listenToAll: (fn) => {
    Breakpoints.getSmallMQL().addListener(fn);
    Breakpoints.getMediumMQL().addListener(fn);
    Breakpoints.getLargeMQL().addListener(fn);
    Breakpoints.getXLargeMQL().addListener(fn);
    Breakpoints.getXXLargeMQL().addListener(fn);
  },

  /**
   * Remove a listener function from all match media queries
   */
  removeFromAll: (fn) => {
    Breakpoints.getSmallMQL().removeListener(fn);
    Breakpoints.getMediumMQL().removeListener(fn);
    Breakpoints.getLargeMQL().removeListener(fn);
    Breakpoints.getXLargeMQL().removeListener(fn);
    Breakpoints.getXXLargeMQL().removeListener(fn);
  },
};

export default Breakpoints;
