/**
 * @param {string} url
 * @returns {boolean}
 */
function urlShouldBeInlined(url) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.searchParams.get('inline-contents') === 'true';
  } catch {
    return false;
  }
}

/**
 * Modifies data to inline specific embeds.  Inlining embeds is done so that
 * certain embeds appear immediately.
 *
 * @param {{ marquees?: { hidden?: boolean, marqueeType?: string, embed?: { url?: string, preloadedHtml?: string } }[] }} [layout]
 * @returns {Promise<{ marquees?: { hidden?: boolean, marqueeType?: string, embed?: { url?: string, preloadedHtml?: string } }[] } | undefined>}
 */
export async function preloadMarqueeEmbedContent(layout) {
  await Promise.all(
    (layout?.marquees || []).map(async (marquee) => {
      if (
        !marquee.hidden
        && marquee.marqueeType === 'embed'
        && marquee.embed?.url
        && urlShouldBeInlined(marquee.embed.url)
      ) {
        // eslint-disable-next-line no-param-reassign
        marquee.embed.preloadedHtml = await fetch(marquee.embed.url)
          .then((r) => r.text())
          .catch(() => undefined);
      }
    }),
  );

  return layout;
}
