import PropTypes from 'prop-types';

import DescriptionPropType from './description';

export default PropTypes.shape({
  description: DescriptionPropType,
  name: PropTypes.string,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      externalUrl: PropTypes.string,
      price: PropTypes.string,
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
      state: PropTypes.string,
    }),
  ),
  promotionalMedia: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.shape({ primary: PropTypes.string }),
    }),
  ),
  teaseImage: PropTypes.shape({
    altText: PropTypes.string,
    height: PropTypes.number,
    url: PropTypes.shape({
      primary: PropTypes.string,
    }),
    width: PropTypes.number,
  }),
});
