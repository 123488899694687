import React from 'react';
import PropTypes from 'prop-types';

import {
  video as videoPropType,
  socialMediaProfiles as socialPropType,
  heroLayoutType as heroLayoutTypePropType,
} from 'lib/CustomPropTypes';

const defaultValue = {
  id: null,
};

export const ArticleContext = React.createContext(defaultValue);

export const articleChildContextTypes = {
  content: videoPropType,
  block: PropTypes.string,
  hasStickyVideo: PropTypes.bool,
  isChromeless: PropTypes.bool,
  isLiveBlog: PropTypes.bool,
  isMobile: PropTypes.bool,
  isRecipe: PropTypes.bool,
  isShoppingSection: PropTypes.bool,
  isShowBlog: PropTypes.bool,
  isPresentationStyleWide: PropTypes.bool,
  isVideoPlaying: PropTypes.bool,
  setStickyVideoFlag: PropTypes.func,
  setVideoPlayState: PropTypes.func,
  socialMediaProfiles: socialPropType,
  vertical: PropTypes.string,
  heroLayoutType: heroLayoutTypePropType,
  authenticated: PropTypes.bool,
  hasTempPass: PropTypes.bool,
  provider: PropTypes.bool,
  videoError: PropTypes.error,
  primaryMediaBannerEnabled: PropTypes.bool,
  pipAlignDesktop: PropTypes.oneOf(['top', 'bottom']),
  pipAlignMobile: PropTypes.oneOf(['top', 'bottom']),
};
