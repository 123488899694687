import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-default-export
export default
PropTypes.shape({
  markupAndEmbeds: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      element: PropTypes.string,
      html: PropTypes.string,
    }),
  ),
});

