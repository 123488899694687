import { SET_AUTO_PLAY } from 'redux/modules/video/types';

export const autoPlayReducer = (state = false, action) => {
  switch (action.type) {
    case SET_AUTO_PLAY:
      return action.payload;

    default:
      return state;
  }
};
