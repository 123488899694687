import api from 'lib/api';
import getDocumentTracingObj from 'lib/getDocumentTracingObj';
import { transformWidgetData } from 'lib/transformWidgetData';
import { CARD_FETCH_COMPLETE } from '../liveBlog';

// Types
export const RELATED_ARTICLE_FETCH_PENDING = 'article/RELATED_ARTICLE_FETCH_PENDING';
export const RELATED_ARTICLE_FETCH_SUCCESS = 'article/RELATED_ARTICLE_FETCH_SUCCESS';
export const RELATED_ARTICLE_FETCH_ERROR = 'article/RELATED_ARTICLE_FETCH_ERROR';

export const MOST_POPULAR_STORIES_LOAD_PENDING = 'mostPopularModule/MOST_POPULAR_STORIES_LOAD_PENDING';
export const MOST_POPULAR_STORIES_LOAD_COMPLETE = 'mostPopularModule/MOST_POPULAR_STORIES_LOAD_COMPLETE';
export const MOST_POPULAR_STORIES_LOAD_FAILED = 'mostPopularModule/MOST_POPULAR_STORIES_LOAD_FAILED';

export const ARTICLE_LOAD_PENDING = 'article/ARTICLE_LOAD_PENDING';
export const ARTICLE_LOAD_COMPLETE = 'article/ARTICLE_LOAD_COMPLETE';
export const ARTICLE_LOAD_FAILED = 'article/ARTICLE_LOAD_FAILED';

export const ARTICLE_PERMISSION_LOAD_PENDING = 'article/ARTICLE_PERMISSION_LOAD_PENDING';
export const ARTICLE_PERMISSION_LOAD_COMPLETE = 'article/ARTICLE_PERMISSION_LOAD_COMPLETE';
export const ARTICLE_PERMISSION_LOAD_FAILED = 'article/ARTICLE_PERMISSION_LOAD_FAILED';

// Actions
export const loadArticle = (id, { additionalRequestData } = {}) => api({
  endpoint: `article/${id}`,
  types: [ARTICLE_LOAD_PENDING, ARTICLE_LOAD_COMPLETE, ARTICLE_LOAD_FAILED],
  additionalRequestData,
});

export const getStartTodayArticlePermissions = (id, { additionalRequestData } = {}) => api({
  endpoint: `getStartTodayArticlePermissions/${id}`,
  types: [
    ARTICLE_PERMISSION_LOAD_PENDING,
    ARTICLE_PERMISSION_LOAD_COMPLETE,
    ARTICLE_PERMISSION_LOAD_FAILED,
  ],
  additionalRequestData,
});

export const loadMostPopularList = (id, size = 10) => api({
  endpoint: `curatedList/${id}/${size}`,
  types: [
    MOST_POPULAR_STORIES_LOAD_PENDING,
    MOST_POPULAR_STORIES_LOAD_COMPLETE,
    MOST_POPULAR_STORIES_LOAD_FAILED,
  ],
});

// Reducer
export const INITIAL_STATE = {
  loading: false,
  content: [],
  error: null,
  isNativeAd: false,
  presentation: {},
  fetchingRelated: false,
  relatedArticles: null,
};

function buildArticle(data, extensions) {
  const article = data;
  const body = [];

  if (Array.isArray(article.body)) {
    article.body.forEach((item) => {
      try {
        const bodyItem = transformWidgetData(item);

        if (!bodyItem) {
          return;
        }

        body.push(bodyItem);
      } catch (e) {
        // empty
      }
    });
  }

  return {
    ...article,
    body,
    documentTracing: getDocumentTracingObj(article, extensions),
  };
}

export function Article(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ARTICLE_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      };

    case ARTICLE_LOAD_COMPLETE: {
      const { data, extensions } = action.payload;
      const article = data?.article;

      if (!article) {
        return {
          ...state,
          loading: false,
        };
      }

      return {
        ...state,
        content: [
          buildArticle(article, extensions),
        ],
        loading: false,
        isNativeAd: article.nativeAd,
        presentation: (article?.presentation) || {},
      };
    }

    case ARTICLE_LOAD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // TODO: Remove this action when removing all the outdated live blog fetching via redux
    // Update article publish date with publish date of most recent card
    case CARD_FETCH_COMPLETE: {
      // Get first item from card search results
      const firstItemPublishDate = action?.payload?.data?.search?.items?.[0]?.date?.publishedAt ?? null;
      if (firstItemPublishDate && Array.isArray(state.content) && state.content.length) {
        // Set date value
        const { content } = state;
        if (content[0]?.date?.publishedAt) {
          content[0].date.publishedAt = firstItemPublishDate;
          // Update state
          return {
            ...state,
            content,
          };
        }
      }
      // Do not alter state if first item was not found
      return {
        ...state,
      };
    }

    case MOST_POPULAR_STORIES_LOAD_PENDING:
      return {
        ...state,
        isLoadingMostPopularStories: true,
      };

    case MOST_POPULAR_STORIES_LOAD_COMPLETE: {
      const { data } = action.payload;
      const mostPopularStoryList = data?.curatedList;

      if (!mostPopularStoryList) {
        return {
          ...state,
          isLoadingMostPopularStories: false,
          mostPopularStoryList: [],
        };
      }

      return {
        ...state,
        mostPopularStoryList,
        isLoadingMostPopularStories: false,
      };
    }

    case MOST_POPULAR_STORIES_LOAD_FAILED:
      console.error('Could not retrieve stories for Most Popular Module');
      return {
        ...state,
        isLoadingMostPopularStories: false,
        mostPopularStoryList: [],
      };

    case RELATED_ARTICLE_FETCH_PENDING:
      return {
        ...state,
        fetchingRelated: true,
      };

    case RELATED_ARTICLE_FETCH_SUCCESS: {
      const { data: { search: { items } } } = action.payload;
      return {
        ...state,
        fetchingRelated: false,
        relatedArticles: [
          ...items.map((r) => ({
            ...r,
            url: {
              ...r.url,
              primary: r?.url?.primary,
              short: r?.url?.short,
              slug: r?.url?.slug,
            },
          })),
        ],
      };
    }

    case RELATED_ARTICLE_FETCH_ERROR:
      return {
        ...state,
        fetchingRelated: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

const getArticlesFromApi = ({ taxonomyPath, currentArticle, queryLimit }, actionTypes) => {
  const filters = [
    'type:Article',
    `NOT id:${currentArticle}`,
    `taxonomy:${taxonomyPath}`,
    'autoCuration:true',
  ];
  const endpoint = `/article/related?filters=${filters.join(' AND ')}&size=${queryLimit}`;
  return api({
    endpoint,
    types: actionTypes,
  });
};

export const fetchRelatedArticles = (args) => getArticlesFromApi(
  args,
  [RELATED_ARTICLE_FETCH_PENDING, RELATED_ARTICLE_FETCH_SUCCESS, RELATED_ARTICLE_FETCH_ERROR],
);
