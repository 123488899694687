import PropTypes from 'prop-types';

export default PropTypes.shape({
  playmakerArt: PropTypes.string,
  playmakerAutoplayMuted: PropTypes.bool,
  playmakerDescription: PropTypes.string,
  playmakerFlag: PropTypes.string,
  playmakerId: PropTypes.string,
  playmakerPlayBehavior: PropTypes.string,
  playmakerSlot: PropTypes.number,
  playmakerTitle: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  playmakerToggle: PropTypes.bool,
});
