import PropTypes from 'prop-types';

import url from './url';

export default PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.shape({
    primary: PropTypes.string,
  }),
  slug: PropTypes.string,
  url,
  dateCreated: PropTypes.string,
  dateModified: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  isCurated: PropTypes.bool,
  pageRoute: PropTypes.string,
  taxonomyType: PropTypes.string,
});
