import PropTypes from 'prop-types';
import taxonomy from './taxonomy';

export default PropTypes.shape({
  labels: PropTypes.arrayOf(taxonomy),
  primarySection: taxonomy,
  primarySubverticals: taxonomy,
  primaryTopic: taxonomy,
  primaryVertical: taxonomy,
  sections: PropTypes.arrayOf(taxonomy),
  subverticals: PropTypes.arrayOf(taxonomy),
  topics: PropTypes.arrayOf(taxonomy),
});
