import { apiFetchJsonp } from 'lib/apiFetchJsonp';
import { formatScheduleFieldsByDay, formatScheduleRequest } from 'lib/scheduleUtils';

// Types
export const SCHEDULE_PENDING = 'schedule/SCHEDULE_PENDING';
export const SCHEDULE_COMPLETE = 'schedule/SCHEDULE_COMPLETE';
export const SCHEDULE_FAILED = 'schedule/SCHEDULE_FAILED';

// Actions
export const loadSchedules = (vertical) => apiFetchJsonp({
  endpoint: formatScheduleRequest(vertical),
  types: [SCHEDULE_PENDING, SCHEDULE_COMPLETE, SCHEDULE_FAILED],
});

// Reducer
const INITIAL_STATE = {
  loading: false,
  formattedItems: null,
};

export const schedule = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SCHEDULE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case SCHEDULE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case SCHEDULE_COMPLETE: {
      const { entries } = action.payload;
      const formattedItems = formatScheduleFieldsByDay(entries);

      return {
        ...state,
        loading: false,
        formattedItems,
      };
    }

    default:
      return state;
  }
};
