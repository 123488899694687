/**
 * Derive HTTP response status code from page props
 *
 * @param {object} pageProps
 * @returns {string}
 */
function getDerivedHttpResponseStatusCodeFromPageProps(pageProps) {
  // Wrapped components have a different getInitialProps return shape
  const {
    statusCode: pagePropsInitialStatusCode,
    initialProps: {
      statusCode: pagePropsInitialWrappedComponentStatusCode,
    } = {},
  } = pageProps;

  // Only one of these values will exist
  const pagePropsStatusCode = pagePropsInitialStatusCode || pagePropsInitialWrappedComponentStatusCode;

  return pagePropsStatusCode;
}

export { getDerivedHttpResponseStatusCodeFromPageProps };
