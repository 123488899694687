const embedPathIdentifiers = [
  '/embedded-newsletter/',
  '/embedded-pkg',
  '/embedded-video',
  '/playmaker/',
];
const embedPathIdentifierPattern = new RegExp(embedPathIdentifiers.join('|'));

/**
 * Determine if a path is for an embed
 * @param {string} path
 * @returns {boolean}
 */
function isEmbedPath(path) {
  return embedPathIdentifierPattern.test(path);
}

export { isEmbedPath };
