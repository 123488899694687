const get = require('lodash.get');

// Key for global accessEnabler variable
const ACCESS_ENABLER_OBJ = '__AccessEnabler';

const TVE_CANONICAL_ROUTE = 'live';

// This is the format of the object provided in the MVPD request.
// Here we are mapping the provided key to something less verbose,
// And filtering off the properties we do not need.
const providerTransform = {
  featured: 'featured',
  field_mvpd_authorized_err: 'errAuth',
  field_mvpd_display_name: 'displayName',
  field_mvpd_freewheel_hash: 'freewheelHash',
  field_mvpd_generic_err: 'errGeneric',
  field_mvpd_id: 'id',
  field_white_logo_2x: 'logo2xWhite',
  field_mvpd_dark_color: 'logoColor',
  // title: '',
  // field_color_logo_2x: 'logo2xColor',
  // field_mvpd_k2_id: 'idK2',
  // field_enable_new_window_workflow: '',
  // field_mvpd_logo_alternative: 0,
  // field_mvpd_logo_dark: '',
  // field_mvpd_logo_light: '',
  // field_mvpd_url: '',
  // field_mvpd_light_color: '',
  // weight: 0,
  // field_mvpd_internal_err: '',
  // is_darker_logo: 1
  concurrencyFlow: 'concurrencyFlow',
};

// Return only the values we want in a format we prefer.
const providerMap = (providers) => {
  if (Array.isArray(providers)) {
    return providers.map((provider) => {
      const transformKeys = Object.keys(providerTransform);
      // Replace source key with friendly key
      return Object.keys(provider)
        .filter((k) => transformKeys.includes(k))
        .reduce((obj, k) => {
          // eslint-disable-next-line no-param-reassign
          obj[providerTransform[k]] = provider[k];
          return obj;
        }, {});
    });
  }
  return providers;
};

const configAccessEnabler = {
  stage: {
    script: 'https://entitlement.auth-staging.adobe.com/entitlement/v4/AccessEnabler.js',
    // eslint-disable-next-line max-len
    softwareStatement: 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwMzQ5NWIzNC02YWRiLTRlMTYtYjg5NS03YjEzNWNiYmM5YTIiLCJuYmYiOjE1NzI0ODE0MzEsImlzcyI6ImF1dGguYWRvYmUuY29tIiwiaWF0IjoxNTcyNDgxNDMxfQ.wJsXxDo4UUHzNvfS2cpuwKUIzPYw2mIY0nn316jCQRptHOLkHAlxVlu_O9fsTIL-Oawto-0Z-e86fln7arlSr7gZBbY1tp31SnHogHWjmdVSkLdXnvCwlRUiVlIBi4CgHHy22IVQYi7DptzKqwbH0WXDxkNBLHqZV7wxr6Dcq26PjJqFd5YCc-PJFL3nQMcO2eAwhOwAldpMEUhr7Fk6LY9Vw93ZClRJoVF15glespy_VcBARW0422JvHrlrJA3KBey5Nz9n9xsDq478g4yVop7KjOQp61cWJTt8N_MO4xtT8SU2iUty2ePdY5QEaAl6asnzdu0xZhudf3ZasJ0bog',
  },
  prod: {
    script: 'https://entitlement.auth.adobe.com/entitlement/v4/AccessEnabler.js',
    // eslint-disable-next-line max-len
    softwareStatement: 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwMDRkMTk2ZC1iNWM3LTQ1NWItOTVkYi04MDcyN2QwM2IzNTEiLCJuYmYiOjE1NzI0ODE2ODAsImlzcyI6ImF1dGguYWRvYmUuY29tIiwiaWF0IjoxNTcyNDgxNjgwfQ.ogEH3mMUoIAJfVoB9iwpsWaoCxgSWrtGnELDaykjx--IjJ1cdModjiTkUKwfemhHbCZZz81nAxBv6-PysPRS3UoLKUlr5z1s4SnFiNuYwRCtrtdiui883i5p42iZ2T6PPQy1QN6_ElfAOC506B57ZIGR25CglBagx5AIjDUTjZeV0hwYaZYuo-_GoBX9RG6h1BWYyKXsnKyTMmgIa0V1dOElCjH5lHUmvwUqaG1T4XyFFfuahqf4O6Sj5ZrSLWZOcNeFV1ePGW1knDp_ugUu3_QQvHuFINhyq5ob3K6VEGXtB3fjlpuT9Ww5AkdIi89fXb4pweOhLNAj2utUBIir5g',
  },
};

const getAccessEnablerConfig = (production = true) => {
  const env = production ? 'prod' : 'stage';
  return (key) => configAccessEnabler[env][key];
};

const configTVE = {
  // General
  iframeSrc: '/blank.html',
  // Cookie path
  path: '/',
  // Domain specific configuration
  'msnbc.com': {
    backgroundLogin: true,
    backgroundLogout: false,
    channelTitle: 'msnbc',
    guid: 2007680,
    itemTitle: 'MSNBC',
    requestorID: 'msnbc',
    stream: 'MSNBC_TVE',
  },
  // Temporarily adding this to enable MSNBC TVE on NBCNews.com
  'nbcnews.com': {
    backgroundLogin: true,
    backgroundLogout: false,
    channelTitle: 'msnbc',
    guid: 2007680,
    itemTitle: 'MSNBC',
    requestorID: 'msnbc',
    stream: 'MSNBC_TVE',
  },
};

const configTempPass = {
  // How often in seconds to check for authorization while a user has tempPass
  checkInterval: 60,
  // Configuration of cookie to prevent tempPass rechecking
  checkTTL: 20 * 60000,
  checkName: 'tve-temppass-check',
  // Configuration of cookie to store tempPass expiration
  expirationName: 'tve-temppass-exp',
  expirationTTL: 10 * 60000,
  // When to reset the tempPass cookie
  reset: {
    utcHours: 5,
    utcMinutes: 0,
  },
};

// Available EPG feed URLs
const availableProgrammingGuides = {
  msnbc: {
    linear: 'https://feed.entertainment.tv.theplatform.com/f/HNK2IC/prod-msnbc-live-listing',
  },
};

// These are all the fields available. Those values uncommented will be requested in the feed fetch.
const programmingGuideFields = [
  'guid',
  'startTime',
  'program.title',
  'program.guid',
  'program.description',
  'program.runtime',
  'seriesId',
  // 'airingType',
  // 'contentRatings',
  // 'endTime',
  // 'programId',
  // 'program.credits',
  // 'program.displayGenre',
  // 'program.languages',
  // 'program.lastPubDate',
  // 'program.programType',
  // 'program.pubDate',
  // 'program.ratings',
  // 'program.secondaryTitle',
  // 'program.seriesEpisodeNumber',
  // 'program.shortTitle',
  // 'program.tvSeasonEpisodeNumber',
  // 'program.tvSeasonId',
  // 'program.tvSeasonNumber',
  // 'program.nbcu$category',
  // 'showingType',
  // 'nbcu$externalAdvertiserId',
  // 'nbcu$fwReplaceSecondaryIds',
  // 'nbcu$isTms'
];

// Format the programming guide URL
const getScheduleFeed = ({
  vertical,
  type = 'linear',
  form = 'cjson',
  count = 14,
  fields = programmingGuideFields,
  startTime,
  endTime,
}) => {
  const dateTime = new Date().getTime();
  const normalizedStartTime = startTime || dateTime;
  const normalizedEndTime = endTime || dateTime + 86400000;
  // Get URL to programming guide feed
  const guideURL = get(availableProgrammingGuides, `${vertical}.${type}`, false);
  if (guideURL) {
    return `${guideURL}`
      + `?form=${form}`
      + `&range=1-${count}`
      + `&fields=${fields.join(',')}`
      + `&byEndTime=${normalizedStartTime}~${normalizedEndTime}`
      + '&sort=startTime|asc';
  }
  return false;
};

const mapScheduleFeed = (schedule) => {
  const copySchedule = { ...schedule };
  if (copySchedule.entries && Array.isArray(copySchedule.entries)) {
    copySchedule.entries = copySchedule.entries.map((entry) => {
      const modified = { ...entry };
      // Convert to correct time zone
      const startTimeFormatted = new Date(
        modified.startTime,
      ).toLocaleTimeString('en-US', { timeZone: 'America/New_York' });
      // Format into HH:MM AM/PM ET
      modified.startTimeFormatted = startTimeFormatted.replace(
        /(\d+):(\d+):\d+\s((a|p)m)/i,
        '$1:$2 $3 ET',
      );
      // Overwrite value in entry
      return modified;
    });
  }
  return copySchedule;
};

// Available EPG thumbnails
const availableEPGThumbnails = {
  msnbc: {
    linear: 'https://nodeassets.nbcnews.com/msnbc/schedule/thumbnails',
  },
};

// Build the URL to the thumbnail index and files
const getScheduleThumbnailURL = (vertical, type = 'linear', file = 'index.json') => {
  const indexBase = get(availableEPGThumbnails, `${vertical}.${type}`, false);
  if (indexBase) {
    return `${indexBase}/${file}`;
  }
  return false;
};

// Format the schedule thumbnails
const mapScheduleThumbnails = (thumbnails) => {
  const modified = {};
  if (Array.isArray(thumbnails)) {
    thumbnails.forEach((entry) => {
      if (entry.guid && entry.title) {
        modified[entry.guid] = entry.title;
      }
    });
  }
  return modified;
};

// Apply individual or default thumbnails to each schedule entry
const applyThumbnailsToSchedule = ({
  schedule,
  thumbnails = {},
  type = 'linear',
  vertical,
}) => {
  const copySchedule = { ...schedule };
  if (copySchedule.entries && Array.isArray(copySchedule.entries)) {
    copySchedule.entries = copySchedule.entries.map((entry) => {
      const modified = { ...entry };
      // Default thumbnail
      modified.thumbnail = getScheduleThumbnailURL(vertical, type, 'default142w.mp4');
      modified.thumbSm = getScheduleThumbnailURL(vertical, type, 'default142w.mp4');
      modified.thumbMd = getScheduleThumbnailURL(vertical, type, 'default750w.mp4');
      // Replace default with assigned thumbnail
      let { seriesId } = modified;
      if (seriesId && typeof seriesId === 'string') {
        seriesId = seriesId.substring(seriesId.lastIndexOf('/') + 1);
        if (typeof thumbnails === 'object' && thumbnails[seriesId]) {
          modified.thumbnail = getScheduleThumbnailURL(vertical, type, `${seriesId}/142w.mp4`);
          modified.thumbSm = getScheduleThumbnailURL(vertical, type, `${seriesId}/142w.mp4`);
          modified.thumbMd = getScheduleThumbnailURL(vertical, type, `${seriesId}/750w.mp4`);
        }
      }
      return modified;
    });
  }
  return copySchedule;
};

/**
 * Map the error codes where the user should be notified to a message.
 */
const notifyErrorMap = {
  Z100: 'Unfortunately, this stream is not available with your cable subscription.',
  Z130: 'Something went wrong, please <i>try again</i> or use a different browser.',
};

/**
 * Return a formatted resourceID used in TVE authentication.
 * @param {string} channelTitle
 * @param {string} itemTitle
 * @param {string} guid
 */
// eslint-disable-next-line max-len
const getResourceID = (channelTitle, itemTitle, guid) => `<rss version="2.0" xmlns:media="http://search.yahoo.com/mrss/">
  <channel>
    <title>${channelTitle}</title>
    <item>
      <title>${itemTitle}</title>
      <guid>${guid}</guid>
    </item>
  </channel>
</rss>`;

module.exports = {
  ACCESS_ENABLER_OBJ,
  applyThumbnailsToSchedule,
  configTempPass,
  configTVE,
  getAccessEnablerConfig,
  getResourceID,
  getScheduleFeed,
  getScheduleThumbnailURL,
  mapScheduleFeed,
  mapScheduleThumbnails,
  notifyErrorMap,
  providerMap,
  TVE_CANONICAL_ROUTE,
};
