import PropTypes from 'prop-types';

import dimensions from './dimensions';

export default PropTypes.shape({
  s: dimensions,
  m: dimensions,
  l: dimensions,
  xl: dimensions,
});
