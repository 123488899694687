import getDocumentTracingObj from 'lib/getDocumentTracingObj';

// Import Types from shared file
import {
  LOAD_SUCCESS,
  LOAD_ERROR,
  SET_CURRENT,
} from 'redux/modules/video/types';

const INITIAL_STATE = null;

export const currentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_SUCCESS: {
      const { data: { video }, extensions } = action.payload;
      return {
        ...action.payload.data.video,
        documentTracing: getDocumentTracingObj(video, extensions),
        error: null,
      };
    }

    case LOAD_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case SET_CURRENT:
      return action.payload;

    default:
      return state;
  }
};
