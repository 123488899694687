import { getPrimaryProductOfferPricesAndAvailability } from './productOfferPriceHelpers';
import { getRamenBentoAPIUrl } from './getRamenBentoAPIUrl';

/**
 * @typedef {Object} WaffleFilterOption
 * @property {boolean} active Indicates if the filter option is enabled/disabled
 * @property {string} key The value associated with the filter option
 * @property {string} name The label associated with the filter option
 */

/**
 * @typedef {Object} FilterOptionConfig
 * @property {string} value The value to configure the filter option
 * @property {string} label The label to configure the filter option
 */

/**
 * @typedef {Object} FilterConfig
 * @property {string} filterLabel The label of the filter (for example, "price")
 * @property {string} type The type of filter (taxonomy, price, etc)
 * @property {Array<FilterOptionConfig>} filterItems config items for the filter options
 * @property {string} id Unique Id of the filter
 */

/**
 * @typedef {Object} CustomFilterOptionTaxonomy
 * @property {string} taxonomyName The label associated with the taxonomy
 * @property {string} taxonomyPath The path associated with the taxonomy
 */

/**
 * @typedef {Object} CustomFilterOptionCuratorConfig
 * @property {CustomFilterOptionTaxonomy} itemTaxonomy The taxonomy for the option
 */

/**
 * @typedef {Object} CustomFilterCuratorConfig
 * @property {string} filterLabel The label of the filter (for example, "price")
 * @property {string} type The type of filter (taxonomy, price, etc)
 * @property {Array<CustomFilterOptionCuratorConfig>} filterItems config items for the filter options
 * @property {string} id Unique Id of the filter
 */

/**
 * @typedef {Object.<string, Array<String>>} TaxonomyMapping
 * @typedef {Object.<string, boolean>} WaffleFilterValue
 * @typedef {Object.<string, WaffleFilterValue>} WaffleFilterValues
 * @typedef {Object.<string, string>} WaffleFilterCategoryToDisplay
 * @typedef {Map.<string, string>} WaffleFilterDisplayNameByKey
 * @typedef {Map.<string, boolean>} WaffleFilterActiveFilters
 * @typedef {Object.<string, Array<WaffleFilterOption>>} WaffleFilterCategories
 */

/**
 * @typedef {Object} InitialFilterValues
 * @property {Object<string, Array<WaffleFilterOption>>} categories The categories that can be filtered
 * @property {Object<string, WaffleFilterValue>} filterValues The categories that can be filtered
 * @property {WaffleFilterDisplayNameByKey} displayNameByKey A mapping of option value -> label
 * @property {Object<string, string>} categoryToDisplay A mapping of filterId -> filterlabel
 */

// API query limit used with Automated Waffles
export const QUERY_LIMIT = 60;

/* Under $25
$25 to $50
$50 to $100
$100 to $200
$200 & Above */
const MAX_PRICE = Number.MAX_SAFE_INTEGER;

const DEFAULT_PRICE_OPTIONS = [{
  value: '0,25', label: 'Under $25', min: 0, max: 25,
},
{
  value: '25,50', label: '$25 to $50', min: 25, max: 50,
},
{
  value: '50,100', label: '$50 to $100', min: 50, max: 100,
},
{
  value: '100,200', label: '$100 to $200', min: 100, max: 200,
},
{
  value: '200,*', label: '$200 & Above', min: 200, max: MAX_PRICE,
},
];

const VALUE_TO_RANGE = DEFAULT_PRICE_OPTIONS.reduce(
  (acc, { value, min, max }) => ({ ...acc, [value]: { min, max } }),
  {},
);

/**
 * Transforms the config for a filter option into a waffle filter option
 * @param {Object} ConfigOption
 * @param {string} ConfigOption.label Label of the option
 * @param {string} ConfigOption.value Value of the option
 * @returns {WaffleFilterOption}
 */
const transformOption = ({ label: itemLabel, value: itemValue }) => ({
  name: itemLabel,
  key: itemValue,
  active: true,
});

/**
 * Transforms a filter config into a list of waffle filter options
 * @param {Object} filterConfig
 * @param {Array<FilterOptionConfig>} filterConfig.filterItems Type of the filter (price, taxonomy)
 * @returns {Array<WaffleFilterOption>}
 */
const getFilterOptions = ({ filterItems }) => filterItems
  .map(transformOption)
  .filter(({ name, key }) => (name && key));


/**
 * Transforms a filter config into a mapping of value->label
 * @param {Object} filterConfig
 * @param {Array<FilterOptionConfig>} filterConfig.filterItems Type of the filter (price, taxonomy)
 * @returns {WaffleFilterDisplayNameByKey}
 */
const associateValueToLabel = ({ filterItems }) => filterItems
  .filter(({ label, value }) => (label && value))
  .reduce((acc, { label, value }) => {
    acc.set(value, label);
    return acc;
  }, new Map());

/**
 * Transforms the filter config into the initial filter state (all options unselected)
 * @param {Object} filterConfig
 * @param {Array<FilterOptionConfig>} filterConfig.filterItems Type of the filter (price, taxonomy)
 * @returns {WaffleFilterValue}
 */
const getInitialFilterState = ({ filterItems }) => filterItems
  .filter(({ label, value }) => (label && value))
  .reduce((acc, { value }) => {
    acc[value] = false;
    return acc;
  }, {});

/**
 * Transforms a price config to conform with the expected config shape
 * @param {Object} filterConfig
 * @param {string} filterConfig.label Label of the price filter
 * @returns {FilterConfig}
 */
const transformPriceFilterConfig = ({ label = 'Price' }) => ({
  filterLabel: label,
  filterItems: DEFAULT_PRICE_OPTIONS,
  type: 'price',
  id: 'priceFilter',
});

/**
 * Transform a taxonomy config to conform with the expected config shape
 * @param {Object} filterConfig
 * @param {string} filterConfig.filterLabel Label of the filter
 * @param {number} idx Index used to give the label a unique key
 * @returns {FilterConfig}
 */
const transformTaxonomyFilterConfig = ({ filterLabel = 'All', filterItems = [] }, idx) => ({
  type: 'taxonomy',
  id: `customFilter${idx + 1}`,
  filterLabel,
  filterItems: filterItems
    .map(({ itemTaxonomy: { taxonomyPath, taxonomyName } = {} }) => ({
      label: taxonomyName,
      value: taxonomyPath,
    })),
});

/**
 * Transforms the filter configs provided by curator into the initial filter States
 * @param {Array<CustomFilterCuratorConfig>} customFilterConfigs
 * @param {Object} priceFilterConfig Config for the price filter
 * @param {string} priceFilterConfig.label Label for the price filter
 * @returns {InitialFilterValues}
 */
export const getInitialFilters = (customFilterConfigs = [], priceFilterConfig = null) => {
  const priceFiltersWithIdAndType = priceFilterConfig
    ? [transformPriceFilterConfig(priceFilterConfig)]
    : [];

  const customFiltersWithIdAndType = customFilterConfigs.map(transformTaxonomyFilterConfig);

  const allConfigs = [...customFiltersWithIdAndType, ...priceFiltersWithIdAndType];

  const allCategories = allConfigs.reduce((acc, config) => {
    const { id } = config;
    const category = getFilterOptions(config);
    return { ...acc, [id]: category };
  }, {});

  const displayNameAssociations = allConfigs
    .map(associateValueToLabel)
    .reduce((acc, currDisplayNameMap) => new Map([...acc, ...currDisplayNameMap]), new Map());


  const initialFilterValues = allConfigs.reduce((acc, config) => {
    const { id } = config;
    const category = getInitialFilterState(config);
    return { ...acc, [id]: category };
  }, {});


  const categoryToDisplay = allConfigs.reduce((acc, { id, filterLabel }) => {
    acc[id] = filterLabel;
    return acc;
  }, {});

  return {
    categories: allCategories,
    filterValues: initialFilterValues,
    displayNameByKey: displayNameAssociations,
    categoryToDisplay,
  };
};

/**
 * Reduces the waffle filter state into the a list of the selected filters
 * @param {WaffleFilterValue} filterValues The state of the filter selections
 * @returns {Array<string>} Values of the selected filters
 */
export const getActiveFilters = (filterValues = {}) => Object.entries(filterValues)
  .filter(([, active]) => active)
  .map(([filterKey]) => filterKey);


/**
 * Returns a function that iterates through each price filter and checks if
 * any of an item's price is within any of the active filter ranges (boolean OR)
 * @param {WaffleFilterValue} filter The taxonomy filter to build a filter function for
 * @returns {(item:Tease) => boolean} Function to apply to item in order to filter by price
 */
const getPriceFilterFn = (filter) => (item) => {
  if (!item?.item) {
    return true;
  }
  const { list, sale } = getPrimaryProductOfferPricesAndAvailability(item?.item);
  const price = sale || list;
  const filterValues = getActiveFilters(filter);
  if (!filterValues.length) {
    return true;
  }
  const isWithinPxRanges = filterValues.some((filterValue) => {
    const { min, max } = VALUE_TO_RANGE[filterValue];
    return (price >= min) && (price <= max);
  });
  return isWithinPxRanges;
};

/**
 * Returns a function that iterates through each taxonomy filter and checks if
 * any of an item's taxonomy terms match any of the active filters (boolean OR)
 * @param {WaffleFilterValue} filter The taxonomy filter to build a filter function for
 * @param {TaxonomyMapping} taxonomyMap The mapping of document id -> taxonomy terms
 * @returns {(item:Tease) => boolean} Function to apply to item in order to filter
 */
const getTaxonomyFilterFn = (filter, taxonomyMap) => (item) => {
  const { id } = item;
  const itemTaxonomyList = taxonomyMap[id] ?? [];
  const filterValues = getActiveFilters(filter);
  if (!filterValues.length) {
    return true;
  }
  const containsFilterValue = filterValues
    .some((filterValue) => itemTaxonomyList
      .some((taxonomy) => new RegExp(filterValue)
        .test(taxonomy)));
  return containsFilterValue;
};

/**
 * Provides a function encapsulating the logic to apply the filter
 * @param {WaffleFilterValue} filter The filter to create a filter function for
 * @param {TaxonomyMapping} taxonomyMap Mapping of document id->taxonomy terms
 * @param {string} filterType Type of the filter
 * @returns {(item:Tease) => boolean} Function to apply to item in order to filter
 */
const getFilterFns = (filter, taxonomyMap, filterType = 'taxonomy') => {
  if (filterType === 'taxonomy') {
    return getTaxonomyFilterFn(filter, taxonomyMap);
  }
  if (filterType === 'price') {
    return getPriceFilterFn(filter);
  }
  return () => true;
};

/**
 * Filters a list of tease items based on provided filters (price, taxonomy)
 * @param {Array<Tease>} items The tease items to filter
 * @param {Any} filters The filters to apply
 * @param {TaxonomyMapping} taxonomyMap A mapping of id->taxonomyTerms, used to filter ids based on taxonomy
 * @returns {Array<Tease>}
 */
export const filterItems = (items, filters, taxonomyMap) => filters.reduce(
  (filteredItems, { type: filterType, values: filterValues }) => {
    const filterFn = getFilterFns(filterValues, taxonomyMap, filterType);
    return filteredItems.filter(filterFn);
  },
  items,
);


/**
 * Extracts all of the taxonomy terms from an item and returns as a list
 * @param {TaxonomyCollection} taxonomy The taxonomy object of a package item
 * @param {Array<Taxonomy>} taxonomy.allTerms The allTerms parameter (all of the taxonomy paths)
 * @returns {Array<string>} All of the taxonomy paths associated with the item
 */
const extractAllTerms = ({ allTerms = [] } = {}) => allTerms.map(({ path }) => path);

/**
 * Transform a list of items into a mapping of id->taxonomy paths list
 * @param {Array<Tease>} items List of teases to extract taxonomy from
 * @returns {TaxonomyMapping} The mapping of id->taxonomy paths
 */
const getItemToTaxonomyMapping = (items = []) => items.reduce((acc, { id, item }) => {
  const { taxonomy } = item || {};
  acc[id] = extractAllTerms(taxonomy);
  return acc;
}, {});


/**
 * Extracts all of the tease items from a curation
 * @param {Curation} param0 Curation to extract items from
 * @returns {Tease[]}
 */
export const reduceCurationToItems = ({ layouts = [] }) => layouts.reduce((itemAcc, { packages = [] }) => {
  const packageItems = packages.reduce((pkgAcc, { items = [] }) => ([
    ...pkgAcc,
    ...items.filter((i) => i),
  ]), []);
  return [...itemAcc, ...packageItems];
}, []);

/**
 * Function to fetch the taxonomy of all the items within packages on the page
 * @param {string} vertical The vertical being rendered
 * @param {string} pageRoute The route of the current page
 * @returns {Promise<TaxonomyMapping>} A mapping of packageId->taxonomy path strings
 */
export const getPackageItemsTaxonomy = async (vertical, pageRoute, draftId, baseUrl = '') => {
  // base url is for server side requests, otherwise
  // use relative path for client side requests
  const url = baseUrl
    ? `${baseUrl}/curation/taxonomy-terms/${vertical}/${
      draftId || -1
    }${pageRoute}`
    : `/bentoapi/curation/taxonomy-terms/${vertical}/${
      draftId || -1
    }${pageRoute}`;
  try {
    const res = await fetch(url, {});
    const { data: { curation = {} } } = await res.json();
    const items = reduceCurationToItems(curation);
    return getItemToTaxonomyMapping(items);
  } catch (error) {
    throw new Error('Failed to Fetch Taxonomy');
  }
};

/**
 * Hydrates a list of non-ad items with ads in the correct slot based on original configuration
 * @param {Array<(Tease|null|undefined)>} filteredItems List of filtered items to insert ads into
 * @param {Array<(Tease|null|undefined)>} originalItems List of original teases to filter, used to inject ads
 * @returns {Array<Tease>}
 */
export const hydrateFilteredItemsWithAds = (filteredItems = [], originalItems = []) => filteredItems
  .reduce((hydratedItems, curItem) => {
    const newHydratedItems = [...hydratedItems];
    let originalitem = originalItems[newHydratedItems.length];
    while (originalitem?.isCustomAd) {
      newHydratedItems.push(originalitem);
      originalitem = originalItems[newHydratedItems.length];
    }
    newHydratedItems.push(curItem);
    return newHydratedItems;
  }, []);


/**
 * Updates the state of the correct filter for a option selection, handling single- and multi-select cases
 * @param {string} key - key of the selected option
 * @param {string} value - value of the selected option
 * @param {string} filterCategory - the identifier of the filter the option belongs to
 * @param {Object<string, WaffleFilterValue>} curFilterValues - the current state of all filters
 * @param {boolean} multiSelect - whether it is single or multi-select
 * @returns {WaffleFilterValue}
 */
export const getUpdatedCategoryValues = (
  key,
  value,
  curFilterValues,
  filterCategory,
  multiSelect,
) => {
  if (multiSelect) {
    return { ...curFilterValues[filterCategory], [key]: value };
  }
  return Object.entries(curFilterValues[filterCategory]).reduce(
    (acc, [curKey]) => {
      acc[curKey] = curKey === key ? value : false;
      return acc;
    },
    {},
  );
};

/**
 * Generates a query string from the current page, and filters
 * where the filters are in the format used by the client React
 * component state
 */
export const buildQueryParams = (page, filters) => {
  const filteredEntries = Object.entries(filters || {}).map(([key, value]) => [
    key,
    Object.entries(value).filter((v) => v[1]),
  ]).filter(([, value]) => value.length > 0);

  const filterString = filteredEntries
    .map(
      ([key, value]) => `${key === 'priceFilter' ? 'price' : key}=${value
        .map((v) => v[0])
        .join('|')}`,
    )
    .join('&');

  return `page=${page}${
    filterString ? `&filters=${encodeURIComponent(filterString)}` : ''
  }`;
};

/**
 * Initial filters is the initial component state of the waffle filters,
 * containing a dictionary of false values. The filterQueryString
 * is the URL query params containing just the values that should be true.
 * This applies the URL filters to the initial filters, and returns
 * the new component state of the filters with the ones from the URL
 * set to true.
 */
export const applyUrlFilters = (initialFilters, filterQueryString = '') => {
  const filters = [];
  filterQueryString.split('&').filter(Boolean).forEach((pair) => {
    const [key, value] = pair.split('=');
    filters.push({ type: key, values: value.split('|') });
  });

  const initialPriceFilter = initialFilters.priceFilter ?? {};
  const urlPriceFilterValues = filters.find((f) => f.type === 'price')?.values ?? [];
  urlPriceFilterValues.forEach((v) => {
    initialPriceFilter[v] = true;
  });

  Object.entries(initialFilters).forEach(([key, values]) => {
    if (key !== 'priceFilter') {
      const valuesMap = values;
      const urlFilterValues = filters.find((f) => f.type === key)?.values ?? [];
      urlFilterValues.forEach((filterKey) => {
        valuesMap[filterKey] = true;
      });
    }
  });

  return { ...initialFilters, priceFilter: initialPriceFilter };
};

export const applyServerPagingAndFilters = async (vertical, section, query, json, pageSize) => {
  const { page: pageString, filters: filterString } = query || {};
  const page = pageString ? parseInt(pageString, 10) : 1;
  const jsonClone = JSON.parse(JSON.stringify(json));
  const waffleClone = jsonClone.data.curation.layouts[0].packages.find(
    (p) => p.type === 'waffle',
  );

  const { customFilters = [], priceFilter = [] } = waffleClone.metadata;
  const taxonomyFilters = customFilters.map((customFilterConfig) => ({
    ...customFilterConfig,
    type: 'taxonomy',
  }));
  const pxFilter = priceFilter[0]
    ? { type: 'price', ...priceFilter[0] }
    : null;
  const { filterValues: initFilters } = getInitialFilters(
    taxonomyFilters,
    pxFilter,
  );
  const finalFilters = Object.entries(
    applyUrlFilters(initFilters, filterString),
  ).map(([key, value]) => ({
    type: key === 'priceFilter' ? 'price' : 'taxonomy',
    values: value,
  }));
  const taxonomy = await getPackageItemsTaxonomy(
    vertical,
    section ? `/${section}` : json.data.curation.pageRoute,
    null,
    getRamenBentoAPIUrl(),
  );

  const filteredItems = filterItems(waffleClone.items, finalFilters, taxonomy);
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  waffleClone.items = filteredItems.slice(start, end);
  waffleClone.pagination = {
    page,
    count: waffleClone.items.length,
    totalCount: filteredItems.length,
    totalPages: Math.ceil(filteredItems.length / pageSize),
    urlFilter: filterString || null,
  };
  return jsonClone;
};

export const checkIfWaffleShouldBeModified = (
  vertical,
  section,
  query,
  json,
  applyServerPagingAndFiltersFunction,
) => {
  if (vertical === 'today' || vertical === 'select') {
    const { curation } = json.data;
    // only product waffles larger than 60 are paginated
    const pageSize = 60;
    const waffle = curation.layouts[0].packages.find(
      (p) => p.type === 'waffle' && p.subType === 'autofilledProductWaffle',
    );
    if (waffle && waffle.items.length > pageSize) {
      return applyServerPagingAndFiltersFunction(
        vertical,
        section,
        query,
        json,
        pageSize,
      );
    }
  }
  return json;
};

/**
 * Updates the state of the active filters selected by a user, handling single- and multi-select cases
 * @param {Map} activeFilters - the current map of filters the user has chosen, to modify or add to
 * @param {boolean} hasMultiSelect - whether it is single or multi-select
 * @param {string} key - key of the selected option
 * @param {string} value - value of the selected option
 * @param {string} category - the identifier of the filter the option belongs to
 * @param {object} displayNameByKey - a mapping of option value -> label
 * @param {object} newCategoryValues - a mapping of the current list of taxonomy in the category
 * @returns {Map}
 */
export const getNewActiveFilters = ({
  activeFilters, hasMultiSelect, key, value, category, displayNameByKey, newCategoryValues,
}) => {
  const newActiveFilters = new Map(activeFilters);

  if (hasMultiSelect) {
    if (value) {
      newActiveFilters.set(key, {
        key,
        category,
        displayName: displayNameByKey.get(key),
      });
    } else {
      newActiveFilters.delete(key);
    }
  } else {
    Object.entries(newCategoryValues).forEach(([curKey, curVal]) => {
      if (!curVal) {
        newActiveFilters.delete(curKey);
      } else {
        newActiveFilters.set(key, {
          key,
          category,
          displayName: displayNameByKey.get(key),
        });
      }
    });
  }

  return newActiveFilters;
};
