import PropTypes from 'prop-types';
import markupAndEmbeds from './markupAndEmbeds';

// eslint-disable-next-line import/no-default-export
export default {
  authors: PropTypes.arrayOf(PropTypes.shape({})),
  content: PropTypes.shape({}),
  date: PropTypes.shape({
    createdAt: PropTypes.string,
    modifiedAt: PropTypes.string,
    publishedAt: PropTypes.string,
  }),
  headline: PropTypes.shape({}),
  id: PropTypes.string,
  primaryImage: PropTypes.shape({}),
  path: PropTypes.string,
  reportingFrom: PropTypes.string,
  factCheckContent: PropTypes.shape({
    analysis: markupAndEmbeds,
    citations: markupAndEmbeds,
    verdict: markupAndEmbeds,
  }),
};
