import api from 'lib/api';

// Import Types from shared file
import {
  TOP_PLAYLIST_PENDING,
  TOP_PLAYLIST_SUCCESS,
  TOP_PLAYLIST_ERROR,
} from 'redux/modules/video/types';

const INITIAL_STATE = [];

// Actions
export const loadTopPlaylist = (id) => api({
  endpoint: `playlist/${id}`,
  types: [TOP_PLAYLIST_PENDING, TOP_PLAYLIST_SUCCESS, TOP_PLAYLIST_ERROR],
});

export const topPlaylistsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOP_PLAYLIST_SUCCESS: {
      const { videoPlaylist } = action.payload.data;
      return [...state, videoPlaylist];
    }

    default:
      return state;
  }
};
