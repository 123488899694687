/* eslint-disable import/no-default-export */
import api from 'lib/api';

import {
  LOAD_SUCCESS,
  LOAD_PLAYLIST_PENDING,
  LOAD_PLAYLIST_SUCCESS,
  LOAD_PLAYLIST_ERROR,
  LOAD_VIDEOS_BY_IDS_SUCCESS,
} from 'redux/modules/video/types';

const INITIAL_STATE = [];

const recommendationHeadline = {
  primary: 'Recommended For You',
};

export const loadAssociatedPlaylist = (id) => {
  const thunk = api({
    endpoint: `playlist/${id}`,
    types: [LOAD_PLAYLIST_PENDING, LOAD_PLAYLIST_SUCCESS, LOAD_PLAYLIST_ERROR],
  });

  return thunk;
};

export const loadVideosByIds = (ids, cacheKey = 'current') => {
  const thunk = api({
    endpoint: `videos/${ids.join(',')}`,
    types: [LOAD_PLAYLIST_PENDING, LOAD_VIDEOS_BY_IDS_SUCCESS, LOAD_PLAYLIST_ERROR],
    cacheKey,
  });

  return thunk;
};

export const associatedPlaylistsReducer = (state = INITIAL_STATE, action) => {
  let data = null;
  if (action.payload) {
    ({ data } = action.payload);
  }

  switch (action.type) {
    case LOAD_PLAYLIST_SUCCESS:
      return [data.videoPlaylist];

    case LOAD_SUCCESS:
      return [{
        ...data.video.associatedVideoPlaylist,
        relatedUrl: data.video.relatedUrl,
      }];

    case LOAD_VIDEOS_BY_IDS_SUCCESS:
      return [{
        headline: recommendationHeadline,
        videos: Object.values(data).filter((v) => v),
      }];

    default:
      return state;
  }
};
