import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from 'locales';

i18n
  // 'detect' cookie set in middleware
  .use(LanguageDetector)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    detection: {
      order: ['htmlTag'],
      lookupCookie: 'i18next',
    },
    debug: false, // true,
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    returnNull: true,
  });
