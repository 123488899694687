import PropTypes from 'prop-types';
import imageSizes from './imageSizes';

export default PropTypes.shape({
  image: PropTypes.shape({
    display: PropTypes.string,
    sizes: imageSizes,
  }),
  headlineSizes: PropTypes.string,
});
