import api from 'lib/api';
import getDocumentTracingObj from 'lib/getDocumentTracingObj';

// Types
const LOAD_PENDING = 'slideshow/LOAD_PENDING';
export const LOAD_SUCCESS = 'slideshow/LOAD_SUCCESS';
const LOAD_ERROR = 'slideshow/LOAD_ERROR';

// Actions
export const loadSlideshow = (id = 1) => api({
  endpoint: `slideshow/${id}`,
  types: [LOAD_PENDING, LOAD_SUCCESS, LOAD_ERROR],
});

// Reducer
export const INITIAL_STATE = {
  loading: false,
  current: null,
};

export function Slideshow(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_PENDING:
      return {
        ...state,
        loading: true,
      };

    case LOAD_SUCCESS: {
      const { data: { slideshow }, extensions } = action.payload;
      return {
        ...state,
        loading: false,
        current: {
          ...slideshow,
          documentTracing: getDocumentTracingObj(slideshow, extensions),
        },
      };
    }

    case LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
