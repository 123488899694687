import PropTypes from 'prop-types';
import { verticalSlugMap } from '../vertical';
// Curator package schema
// https://nbcnewsdigital.atlassian.net/wiki/spaces/STELLA/pages/3041525839/Context+configuration+schema

// get all available verticals to assign as keys for prop type
const verticalDisplayPropType = {
  default: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape({
      layout: PropTypes.string,
      zoneAOnly: PropTypes.bool,
    }),
  ]),
};
Object.keys(verticalSlugMap).forEach((key) => {
  verticalDisplayPropType[key] = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape({
      layout: PropTypes.string,
      zoneAOnly: PropTypes.bool,
    }),
  ]);
});

// prop shape for content field type
const contentFieldPropType = PropTypes.shape({
  field: PropTypes.string,
  displayName: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    // mirroring the prop type as defined in the docs
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    label: PropTypes.string,
    default: PropTypes.bool,
  })),
  info: PropTypes.string,
  maxLength: PropTypes.number,
  toggleDisplay: PropTypes.shape({
    allow: PropTypes.bool,
    defaultValue: PropTypes.bool,
  }),
  conditions: PropTypes.shape({
    show: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string,
      level: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.bool,
    })),
  }),
});

const packageFieldsPropType = PropTypes.shape({
  field: PropTypes.string,
  default: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  description: PropTypes.string,
  displayName: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'checkbox',
    'toggle',
    'select',
    'url',
    'image',
    'number',
    'currency',
    'date',
    'list',
    'playlist',
    'taxonomy',
    'curation',
    'section',
  ]),
  defaultValue: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    // mirroring the prop type as defined in the docs
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    label: PropTypes.string,
    default: PropTypes.bool,
  })),
  related: PropTypes.shape({
    default: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
  }),
});

const contentFieldsByPropertyPropType = PropTypes.shape({
  properties: PropTypes.shape({
    contentTypes: PropTypes.arrayOf(PropTypes.string),
    related: PropTypes.bool,
    urlPattern: PropTypes.string,
  }),
  fields: PropTypes.arrayOf(contentFieldPropType),
});

const packageDefinitionPropType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  default: PropTypes.bool,
  contentTypes: PropTypes.arrayOf(PropTypes.string),
  verticalDisplay: PropTypes.shape(verticalDisplayPropType),
  packageFields: PropTypes.arrayOf(packageFieldsPropType),
  contentFields: contentFieldPropType,
  'contentFields.byProperty': contentFieldsByPropertyPropType,
  subTypes: PropTypes.shape({
    type: PropTypes.string,
    displayName: PropTypes.string,
    group: PropTypes.string,
    default: PropTypes.bool,
    contentTypes: PropTypes.arrayOf(PropTypes.string),
    verticalDisplay: PropTypes.shape(verticalDisplayPropType),
    packageFields: PropTypes.arrayOf(packageFieldsPropType),
    contentFields: contentFieldPropType,
    'contentFields.byProperty': contentFieldsByPropertyPropType,
  }),
});

export { packageDefinitionPropType, verticalDisplayPropType };
