import { combineReducers } from 'redux';

// Reducers
import { adTest } from './adTest/index';
import { Article as article } from './article/index';
import { front } from './front/index';
import { layout } from './layout/index';
import { liveBlog } from './liveBlog/index';
import { localNews as localnews } from './localnews/index';
import { Menu as menu } from './menu/index';
import { navbar } from './navbar/index';
import { newsletter } from './newsletter/index';
import { person } from './person/index';
import { product } from './product/index';
import { recipeReducer as recipe } from './recipe/index';
import { router } from './router/index';
import { shared } from './shared/index';
import { showBlog } from './showBlog/index';
import { schedule } from './schedule/index';
import { Slideshow as slideshow } from './slideshow/index';
import { TveReducer } from './tve/index';
import { video } from './video/index';
import { waffle } from './waffle/index';

export default combineReducers({
  adTest,
  article,
  front,
  layout,
  liveBlog,
  localnews,
  menu,
  navbar,
  newsletter,
  person,
  product,
  recipe,
  router,
  schedule,
  shared,
  showBlog,
  slideshow,
  tve: TveReducer,
  video,
  waffle,
});
