import getConfig from 'next/config';

import api from 'lib/api';
import getDocumentTracingObj from 'lib/getDocumentTracingObj';

export const AVAILABLE_PREVIEW = 'AVAILABLE_PREVIEW';
export const AVAILABLE_PRODUCTION = 'AVAILABLE_PRODUCTION';

// Types
const RECIPE_LOAD_PENDING = 'recipe/LOAD_PENDING';
export const RECIPE_LOAD_SUCCESS = 'recipe/LOAD_SUCCESS';
export const RECIPE_LOAD_ERROR = 'recipe/LOAD_ERROR';

export const RECIPE_FETCH_PENDING = 'recipe/FETCH_PENDING';
export const RECIPE_FETCH_SUCCESS = 'recipe/FETCH_SUCCESS';
export const RECIPE_FETCH_ERROR = 'recipe/FETCH_ERROR';


// Actions
export const loadRecipe = (id) => {
  const filters = [
    'type:Recipe',
    'id:tdrp*',
    `NOT id:${id}`,
    'taxonomy:today/label/most-popular-recipes',
    'autoCuration:true',
  ];
  return api({
    endpoint: `recipe/${id}?filters=${filters.join(' AND ')}`,
    types: [RECIPE_LOAD_PENDING, RECIPE_LOAD_SUCCESS, RECIPE_LOAD_ERROR],
  });
};

const getRecipesFromApi = ({ courseTypePath, currentRecipe, queryLimit }, actionTypes) => {
  const filters = [
    'type:Recipe',
    'id:tdrp*',
    `NOT id:${currentRecipe}`,
    `taxonomy:${courseTypePath}`,
    'autoCuration:true',
  ];

  const endpoint = `recipe/related?filters=${filters.join(' AND ')}&size=${queryLimit}`;

  return api({
    endpoint,
    types: actionTypes,
  });
};

export const fetchRelatedRecipes = (args) => getRecipesFromApi(
  args,
  [RECIPE_FETCH_PENDING, RECIPE_FETCH_SUCCESS, RECIPE_FETCH_ERROR],
);

// Reducer
export const INITIAL_STATE = {
  current: null,
  fetchingRelated: false,
  loading: false,
  relatedRecipes: null,
};

export function recipeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RECIPE_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      };

    case RECIPE_LOAD_SUCCESS: {
      const { publicRuntimeConfig: { API_URL_BENTO_PUBLIC = '' } = {} } = getConfig();
      const isPreviewEnvironment = API_URL_BENTO_PUBLIC.includes('//preview');

      const {
        data: {
          recipe,
          search: { items: mostPopularRecipes } = {},
        },
        extensions,
      } = action.payload;

      // Passing the isPreviewEnvironment to the component to decide when to show the cart
      recipe.isPreviewEnvironment = isPreviewEnvironment;

      return {
        ...state,
        loading: false,
        current: {
          ...recipe,
          mostPopularRecipes,
          documentTracing: getDocumentTracingObj(recipe, extensions),
        },
      };
    }

    case RECIPE_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RECIPE_FETCH_PENDING:
      return {
        ...state,
        fetchingRelated: true,
      };

    case RECIPE_FETCH_SUCCESS: {
      const { data: { search: { items } } } = action.payload;
      return {
        ...state,
        fetchingRelated: false,
        relatedRecipes: [
          ...items.map((r) => ({
            ...r,
            url: {
              ...r.url,
              primary: r?.url?.primary,
              short: r?.url?.short,
              slug: r?.url?.slug,
            },
            icid: 'recipes_baconmodule',
          })).sort((x, y) => {
            if (x.ecommerceEnabled) {
              return -1;
            }
            if (y.ecommerceEnabled) {
              return 1;
            }
            return 0;
          }),
        ],
      };
    }

    case RECIPE_FETCH_ERROR:
      return {
        ...state,
        fetchingRelated: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
