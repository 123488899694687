import PropTypes from 'prop-types';

export default PropTypes.shape({
  authenticationState: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  token: PropTypes.string,
  authenticate: PropTypes.func,
  subscribe: PropTypes.func,
});
