import PropTypes from 'prop-types';

import authorProps from './author';
import descriptionProps from './description';
import headlineProps from './headline';
import imageProps from './image';
import organizationProps from './organization';
import taxonomyCollection from './taxonomyCollection';
import unibrowProps from './unibrow';
import urlProps from './url';
import videoProps from './video';

export default PropTypes.shape({
  authors: PropTypes.arrayOf(authorProps),
  autoCuration: PropTypes.bool,
  associatedVideo: videoProps,
  body: PropTypes.arrayOf(PropTypes.object),
  chefNotes: PropTypes.arrayOf(PropTypes.object),
  cookTime: PropTypes.string,
  dateCreated: PropTypes.string,
  dateModified: PropTypes.string,
  datePublished: PropTypes.string,
  description: descriptionProps,
  headline: headlineProps,
  hidden: PropTypes.bool,
  id: PropTypes.string,
  ingredients: PropTypes.arrayOf(PropTypes.object),
  instructions: PropTypes.arrayOf(PropTypes.object),
  isAnchorFavorite: PropTypes.bool,
  nativeAd: PropTypes.bool,
  nutrition: PropTypes.shape({
    calories: PropTypes.string,
  }),
  prepTime: PropTypes.string,
  primaryImage: imageProps,
  publisher: organizationProps,
  servingSize: PropTypes.string,
  source: organizationProps,
  taxonomy: taxonomyCollection,
  teaseImage: imageProps,
  totalTime: PropTypes.string,
  type: PropTypes.string,
  unibrow: unibrowProps,
  url: urlProps,
  yield: PropTypes.string,
  mostPopularRecipes: PropTypes.arrayOf(PropTypes.object),
});
