import PropTypes from 'prop-types';

import widget from './widget';
import presentation from './presentation';

export default PropTypes.shape({
  type: PropTypes.string,
  presentation,
  widget,
});
