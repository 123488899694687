import api from 'lib/api';

// Person Actions
const FETCH_PERSONS_LOADING = 'FETCH_PERSONS_LOADING';
const FETCH_PERSONS_SUCCEEDED = 'FETCH_PERSONS_SUCCEEDED';
const FETCH_PERSONS_FAILED = 'FETCH_PERSONS_FAILED';

export const fetchPersonsLoading = () => ({
  type: FETCH_PERSONS_LOADING,
});

export const fetchPersonsSucceeded = (items) => ({
  type: FETCH_PERSONS_SUCCEEDED,
  payload: { items },
});

export const fetchPersonsFailed = (error) => ({
  type: FETCH_PERSONS_FAILED,
  payload: { error },
});

export const loadPersonsAction = () => api({
  endpoint: 'persons/search',
  types: [FETCH_PERSONS_LOADING, FETCH_PERSONS_SUCCEEDED, FETCH_PERSONS_FAILED],
});

// Person Reducer
export const INITIAL_STATE = {
  items: [],
  loading: false,
  error: false,
  pagination: null,
};

export function person(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PERSONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PERSONS_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        items: action.payload.data.search.items,
      };
    }

    case FETCH_PERSONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}
