const TWENTY_THREE_HOURS_IN_MS = 82800000; // 60s * 60m * 23hr * 1000ms

export const isLiveBlogPath = (path) => {
  if (!path) return false;

  return path.split('/')[3] === 'live-blog';
};

/**
 * @param  {String} subType
 * @return {Boolean}
 */
export const shouldRenderRegularBlog = (subType) => subType && subType === 'blog';

export const isSingleCardRoute = (path) => /ncrd\d+$/.test(path);

export const isBlogEventEnded = (flag) => (
  !!flag
  && typeof flag === 'string'
  && flag.toLowerCase() === 'event ended'
);

// N.B. - we use 23 hours because there are 24 hours in 1 day and live blogs need to be closed within 1 day for SEO purposes.
// If 24 hours elapse, we must mark an event ended and editors must create a new Live Blog.
export const isBlogLive = (dateVal) => (dateVal
  && (Date.now() - (new Date(dateVal)).getTime()) < TWENTY_THREE_HOURS_IN_MS);

export default {
  isLiveBlogPath,
  isSingleCardRoute,
  isBlogEventEnded,
  isBlogLive,
};
