import PropTypes from 'prop-types';

import socialMediaProfiles from './socialMediaProfiles';
import url from './url';

/*
  this was extracted from authors. Needed for author type from image which
  is not embedded.
*/

export default PropTypes.shape({
  bio: PropTypes.arrayOf(PropTypes.object),
  email: PropTypes.string,
  id: PropTypes.string,
  jobTitle: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  primaryImage: PropTypes.shape({
    altText: PropTypes.string,
    caption: PropTypes.string,
    encodingFormat: PropTypes.string,
    graphicContent: PropTypes.bool,
    height: PropTypes.number,
    id: PropTypes.string,
    url,
    width: PropTypes.number,
  }),
  shortBio: PropTypes.string,
  socialMediaProfiles,
  type: PropTypes.string,
});
