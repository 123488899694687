/**
 * Single source of shared video redux types and actions.
 *
 * This file reduces the circular dependencies caused by
 * video redux modules including each other.
 */

// Types
export const LOAD_PENDING = 'video/LOAD_PENDING';
export const LOAD_SUCCESS = 'video/LOAD_SUCCESS';
export const LOAD_ERROR = 'video/LOAD_ERROR';
export const SET_AUTO_PLAY = 'video/SET_AUTO_PLAY';

export const SET_CURRENT = 'videos/SET_CURRENT';

export const LOAD_PLAYLIST_SUCCESS = 'video/LOAD_PLAYLIST_SUCCESS';
export const LOAD_PLAYLIST_PENDING = 'video/LOAD_PLAYLIST_PENDING';
export const LOAD_PLAYLIST_ERROR = 'video/LOAD_PLAYLIST_ERROR';

export const LOAD_VIDEOS_BY_IDS_SUCCESS = 'videos/LOAD_VIDEOS_BY_IDS_SUCCESS';

export const TOP_PLAYLIST_PENDING = 'videos/TOP_PLAYLIST_PENDING';
export const TOP_PLAYLIST_SUCCESS = 'videos/TOP_PLAYLIST_SUCCESS';
export const TOP_PLAYLIST_ERROR = 'videos/TOP_PLAYLIST_ERROR';
