import PropTypes from 'prop-types';
import ArticlePropType from './article';

const VerticalPropType = PropTypes.shape({
  src: PropTypes.string,
  article: ArticlePropType,
});

export default PropTypes.shape({
  news: VerticalPropType,
  mach: VerticalPropType,
  better: VerticalPropType,
  think: VerticalPropType,
  globalcitizen: VerticalPropType,
});
