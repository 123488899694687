import PropTypes from 'prop-types';

const articleSection = PropTypes.shape({
  index: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.node),
  ad: PropTypes.shape({
    display: PropTypes.bool,
    slot: PropTypes.string,
  }),
  taboola: PropTypes.bool,
});

export default articleSection;
