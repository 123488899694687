import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayOfYear from 'dayjs/plugin/dayOfYear';

import { getRamenBentoAPIUrl } from 'lib/getRamenBentoAPIUrl';

import { getScheduleFeed } from './tve';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(dayOfYear);

export const ET_TIMEZONE = 'America/New_York';

// Rearrange order to change rendered order on schedule page.
export const DAYS = new Map([
  [1, 'Monday'],
  [2, 'Tuesday'],
  [3, 'Wednesday'],
  [4, 'Thursday'],
  [5, 'Friday'],
  [6, 'Saturday'],
  [0, 'Sunday'],
]);

/**
 * Formatter utility that accepts the JSON return value of a fetch call to the schedule feeds
 * service. Translates an array of entries into an object whos keys are the days of the week, and
 * values are an object with the required fields for rendering by our custom Schedule package.
 *
 * The values in the object are staggered so each day contains content from 5:00 am for that current
 * day, up until 5:00 am in the next day.
 */
export const formatScheduleFieldsByDay = (entries = []) => entries.reduce((days, entry) => {
  const seriesIdLong = (entry?.seriesId) || '';
  const seriesId = seriesIdLong.substr(seriesIdLong.lastIndexOf('/') + 1);
  const formattedEntry = {
    title: entry?.program?.title,
    genre: entry?.program?.displayGenre ?? '',
    startTime: entry.startTime,
    seriesId,
    description: entry?.program?.description ?? '',
  };
  // Entries earlier than 5:00 am get pushed to the previous day
  const date = dayjs(formattedEntry.startTime).tz(ET_TIMEZONE);
  const dayOffset = date.hour() < 5 ? 1 : 0;
  const numDay = date.subtract(dayOffset, 'days').day();
  const day = DAYS.get(numDay);

  if (days[day]) {
    days[day].push(formattedEntry);
  } else {
    // eslint-disable-next-line no-param-reassign
    days[day] = [formattedEntry];
  }

  return days;
}, {});

/**
 * Utility function to check if a provided schedule entry has a start time that takes
 * place a day later than the provided previousEntry, in ET.
 * Both entries are objects configured by formatScheduleFieldsByDay from this file.
 */
export const entryIsNextDayET = (currentEntry, previousEntry) => {
  if (!previousEntry) return false;

  const currentEntryStartTime = currentEntry?.startTime ?? 0;
  const previousEntryStartTime = previousEntry?.startTime ?? 0;

  const currentEntryDate = dayjs(currentEntryStartTime).tz(ET_TIMEZONE);
  const previousEntryDate = dayjs(previousEntryStartTime).tz(ET_TIMEZONE);

  return currentEntryDate.subtract(1, 'day').isSame(previousEntryDate, 'day');
};

const programmingGuideFields = [
  'guid',
  'startTime',
  'program.title',
  'program.displayGenre',
  'program.description',
  'program.runtime',
  'seriesId',
];

/**
 * Formatter function to create parameters for the existing tve URL formatter function.  Primarily
 * use is generating a range of dates (last Sunday to next Sunday)
 */
export const formatScheduleRequest = (vertical) => {
  const now = dayjs().tz(ET_TIMEZONE);

  const startOfLastMonday = now
    .startOf('isoWeek') // ISO 8601 week starts on Monday
    .hour(5) // 5am
    .add(1, 'ms'); // add one millisecond. The Platform API is inclusive, so we need to exclude
    // the previous hour's show. I think this is due to the use of `byEndTime` in `getScheduleFeed`.
    // if there's a `byStartTime` filter for the API, it's possible that could be used instead.
  const endOfNextSunday = now
    .endOf('isoWeek') // ISO 8601 week ends Sunday
    .add(1, 'day') // Monday
    .hour(5) // 5:59:59 am
    .startOf('hour'); // start of 5am (0 mins, 0 secs)

  return getScheduleFeed({
    vertical,
    fields:
    programmingGuideFields,
    count: 250,
    startTime: startOfLastMonday.valueOf(),
    endTime: endOfNextSunday.valueOf(),
  });
};

export const BROADCAST_SCHEDULES_ENDPOINT = 'broadcastSchedules';

export function getBroadcastSchedulesDataUrl() {
  const apiUrl = getRamenBentoAPIUrl();

  return `${apiUrl}/${BROADCAST_SCHEDULES_ENDPOINT}`;
}
