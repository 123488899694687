import PropTypes from 'prop-types';

export default {
  additionalClasses: PropTypes.string,
  eyebrow: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  id: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    aspectRatios: PropTypes.shape({
      s: PropTypes.string,
      m: PropTypes.string,
      l: PropTypes.string,
      xl: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  isShoppable: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
};
