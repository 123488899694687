import PropTypes from 'prop-types';

const price = PropTypes.shape({
  type: PropTypes.string,
  price: PropTypes.string,
  priceCurrency: PropTypes.string,
});

const seller = PropTypes.shape({
  name: PropTypes.string,
  externalUrl: PropTypes.string,
});

export default PropTypes.shape({
  externalUrl: PropTypes.string,
  price: PropTypes.string,
  priceCurrency: PropTypes.string,
  prices: PropTypes.arrayOf(price),
  state: PropTypes.string,
  seller,
});
