import getConfig from 'next/config';


const getUrlParameter = (param) => {
  // build regex to find current param
  const regex = new RegExp(`[\\?&]${param}=([^&#]*)`);
  // regex provides same array output even if search isn't supported
  const results = regex.exec(window.location.search);
  return results === null ? '' : results[1];
};

const getParamFlagStatus = (name) => {
  const flagName = getUrlParameter('flagName');
  const flagValue = getUrlParameter('flagValue') || false;
  if (flagName === name) {
    return flagValue === 'true';
  }
  return null;
};

const getDefaultStatus = (feature = '') => {
  const {
    publicRuntimeConfig: {
      ENABLED_FEATURES,
    },
  } = getConfig();

  const featuresThatAreEnabled = ENABLED_FEATURES; // eslint-disable-line no-undef
  let listOfEnabledFeatures = [];

  if (featuresThatAreEnabled?.length) {
    // strip out double quotes and split by commas
    listOfEnabledFeatures = featuresThatAreEnabled.replace(/["]|[\s]/g, '').split(','); // eslint-disable-line no-undef
  }
  const isEnabledByDefault = listOfEnabledFeatures.includes(feature);
  return !!isEnabledByDefault;
};

const getUpdatedQueryParamStatus = (feature = null, currentStatus = getDefaultStatus(feature)) => {
  if (feature !== null) {
    let queryParamStatus = null;
    if (typeof window !== 'undefined') {
      queryParamStatus = getParamFlagStatus(feature);
    }

    if (queryParamStatus !== null && queryParamStatus !== currentStatus) {
      return !!queryParamStatus;
    }
  }
  return currentStatus;
};

/**
 * Gets the feature config value for a specific brand, falling back to the default
 * @param  {FeatureConfig} featureConfig
 * @param  {String} brand
 */
function getFeatureConfigForBrand(featureConfig, brand) {
  return featureConfig?.[brand] ?? featureConfig.default;
}

export {
  getDefaultStatus,
  getUpdatedQueryParamStatus,
  getFeatureConfigForBrand,
};
