import React, {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import getConfig from 'next/config';
import { FeatureFlagContext, VerticalContext } from 'lib/ContextTypes';
import loadScript from 'lib/loadScript';
import { AIRSHIP_WEB_NOTIFICATIONS } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { reOptInUsersWithExistingPermission } from 'lib/webNotifUtils';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useCookies } from 'react-cookie';
import { stub as $t } from '@nbcnews/analytics-framework';
import { MsnbcPrompt } from './MsnbcPrompt';
import { TodayPrompt } from './TodayPrompt';

dayjs.extend(isSameOrAfter);

export const AirshipWebNotifications = () => {
  const vertical = useContext(VerticalContext);
  const ffCtx = useContext(FeatureFlagContext);
  const airshipSdk = useRef();
  const [config, setConfig] = useState(null);
  const [ffConfigLoaded, setFFConfigLoaded] = useState(false);
  const [cookies, setCookies] = useCookies();
  const [prompt, setPrompt] = useState(false);
  const [sdkLoaded, setSdkLoaded] = useState(false);

  const buildConfigByVertical = () => {
    let brandConfig = null;

    if (config && ffConfigLoaded) {
      return null;
    }

    const brandFeature = getFeatureConfigForBrand(AIRSHIP_WEB_NOTIFICATIONS, vertical);

    // find segment within the vertical
    let segment = null;
    if (vertical === 'msnbc') {
      segment = 'all';
    } else if (vertical === 'today' && (window.location.pathname).includes('/shop')) {
      segment = 'shop';
    }
    if (!segment) {
      return null;
    }

    brandConfig = brandFeature[segment];

    // override brandConfig with feature flag config if present
    const ffConfig = ffCtx?.['airship-web-notifications-config'] || null;
    if (ffConfig) {
      setFFConfigLoaded(true);
    }
    if (ffConfig?.name) {
      brandConfig = {
        ...brandConfig,
        ...ffConfig,
      };
    }
    setConfig(brandConfig);
    return brandConfig;
  };

  const updateSoftOptinCookie = ({ increaseCount, setTimeStamp }) => {
    const cookieValues = cookies?.[config.cookie]?.split(',');
    let softOptinCount = Number(cookieValues?.[0] || 0);
    let softOptinTimeStamp = cookieValues?.[1] || '';

    if (increaseCount) {
      softOptinCount += 1;
    }

    if (setTimeStamp) {
      softOptinTimeStamp = dayjs().format();
    }

    setCookies(config.cookie, `${softOptinCount},${softOptinTimeStamp}`, { path: '/' });

    return softOptinCount;
  };

  const getAirshipWebNotificationsOptOutCount = () => {
    const optOutCount = window.localStorage.getItem(config.optOutCountLocalStorageKey);

    if (optOutCount === null) {
      window.localStorage.setItem(config.optOutCountLocalStorageKey, 0);

      return 0;
    }

    return parseInt(optOutCount, 10);
  };

  const incrementAirshipWebNotificationsOptOutCount = () => {
    const newOptOutCount = parseInt(getAirshipWebNotificationsOptOutCount(), 10) + 1;

    return window.localStorage.setItem(
      config.optOutCountLocalStorageKey,
      newOptOutCount,
    );
  };

  const declineAction = (method) => {
    setPrompt(false);

    incrementAirshipWebNotificationsOptOutCount();

    updateSoftOptinCookie({ setTimeStamp: true });

    $t('track', 'rmn_webNoti_button_press', {
      action: method,
    });
  };

  const acceptAction = () => {
    setPrompt(false);

    airshipSdk.current.register();

    $t('track', 'rmn_webNoti_button_press', {
      action: 'accept',
    });
  };

  /**
  * Compares current opt out count against config to determine if the matching
  * interval duration has passed
  * @param {int} count Current opt out count from local storage
  * @param {string} cookieTimestamp
  * @returns {boolean}
*/
  const getIntervalDelay = (count, cookieTimestamp) => {
    const { softOptInDisplayIntervals } = config;
    const currInterval = softOptInDisplayIntervals
      .find((i) => i.optOutCount === count)?.interval || null;
    const intervalElapsed = currInterval && cookieTimestamp ? dayjs().isSameOrAfter(
      dayjs(cookieTimestamp)
        .add(currInterval[0], currInterval[1]),
    ) : false;
    return intervalElapsed;
  };

  const getResourceByEnvironnment = (resource) => {
    let file = config?.[resource].test;
    const {
      publicRuntimeConfig: {
        CODE_ENVIRONMENT,
      },
    } = getConfig();

    if (CODE_ENVIRONMENT === 'production') {
      file = config?.[resource].prod;
    }
    return file;
  };

  /**
   * Validate cookie timestamp against interval and user opt-out count
   * @returns {object}
   */
  const validateCookieAndOptOutCount = () => {
    const dismissTimeStamp = cookies?.[config.cookie]?.split(',')[1] || false;
    const optOutCount = getAirshipWebNotificationsOptOutCount();
    const cookieValidated = dismissTimeStamp && optOutCount > 0
      ? getIntervalDelay(optOutCount, dismissTimeStamp)
      : true;

    if (!cookies?.[config.cookie]) {
      setCookies(config.cookie, 0, { path: '/' });
    }

    return { cookieValidated, optOutCountValidated: optOutCount < config.softOptInMaxAskLimit };
  };

  // load sdk
  const loadAirshipSDK = (() => {
    const sdkPath = getResourceByEnvironnment('sdkPath');
    loadScript(sdkPath,
      { defer: true }).then(() => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/push-worker.js').then(() => {
          if (typeof UA !== 'undefined') {
            // eslint-disable-next-line no-undef
            UA.then((sdk) => {
              airshipSdk.current = sdk;
              setSdkLoaded(true);
              sdk.addEventListener('channel', (ev) => {
                if (ev.detail.opt_in) {
                  $t('track', 'rmn_webNoti_accepted');

                  // add a tag to the opted in user for segmentation
                  sdk.channel.tags.add(config.channelTag);
                }
              });
            }).catch((error) => {
              console.log('airship: there was an error loading the SDK:', error);
            });
          }
        }, (err) => {
          // registration failed :(
          console.log('ServiceWorker registration failed: ', err);
        });
      }
    });
  });

  const buildPromptDisplay = () => {
    if (prompt) {
      if (vertical === 'msnbc') {
        return <MsnbcPrompt acceptAction={acceptAction} declineAction={declineAction} />;
      }
      if (vertical === 'today') {
        return <TodayPrompt acceptAction={acceptAction} declineAction={declineAction} />;
      }
    }
    return null;
  };

  // one-time analytics event registration
  useEffect(() => {
    $t('register', [
      'rmn_webNoti_show',
      'rmn_webNoti_button_press',
      'rmn_webNoti_accepted',
    ]);
  }, []);

  // get config, load the sdk, validate cookies/local storage, check out of sync registration, display prompt
  useEffect(() => {
    buildConfigByVertical();
    if (config === null) {
      return;
    }

    if (!sdkLoaded) {
      loadAirshipSDK();
    } else {
      const { cookieValidated, optOutCountValidated } = validateCookieAndOptOutCount();

      const userShouldBeReOptedIn = reOptInUsersWithExistingPermission(airshipSdk?.current);
      if (userShouldBeReOptedIn) {
        airshipSdk.current.register();
        return;
      }

      const userShouldBePrompted = (
        !airshipSdk.current.channel?.opt_in
        && airshipSdk.current.permission === 'default'
        && airshipSdk.current.isSupported
        && airshipSdk.current.canRegister
        && cookieValidated
        && optOutCountValidated
      );

      if (userShouldBePrompted) {
        setTimeout(() => {
          setPrompt(true);
          $t('track', 'rmn_webNoti_show', {
            instance: updateSoftOptinCookie({ increaseCount: true }),
          });
        }, config.softOptInDisplayDelay);
      }
    }
  }, [airshipSdk, config, sdkLoaded]);

  return buildPromptDisplay();
};
