import api from 'lib/api';

// Types
const LOAD_SUCCESS = 'video/LOAD_RELATED_SUCCESS';
const LOAD_PENDING = 'video/LOAD_RELATED_PENDING';
const LOAD_ERROR = 'video/LOAD_RELATED_ERROR';

// Actions
export const loadRelatedArticle = (id) => api({
  endpoint: `article/${id}`,
  types: [LOAD_PENDING, LOAD_SUCCESS, LOAD_ERROR],
});

const INITIAL_STATE = {};

export const relatedArticleReducer = (state = INITIAL_STATE, action) => {
  let data;
  if (action.payload) {
    ({ data } = action.payload);
  }
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        headline: (data.article?.headline) || {},
        description: (data.article?.description) || {},
        ecommerceEnabled: data.article?.ecommerceEnabled,
        url: (data.article?.url?.primary) || '',
      };

    default:
      return state;
  }
};
