import BTE from 'lib/BTE';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { shouldRenderRegularBlog } from 'lib/liveBlog';
import { transformWidgetData } from 'lib/transformWidgetData';
import { getRamenBentoAPIUrl } from 'lib/getRamenBentoAPIUrl';

export const CARD_QUERY_LIMITS = {
  FIRST_PAGE: 150,
  FIRST_PAGE_TWITTER: 15,
  LOAD_MORE: 10,
};

const FIRST_PAGE_SIZE = CARD_QUERY_LIMITS.FIRST_PAGE;

dayjs.extend(utc);

export const articleViewHandler = (event, props) => {
  if (event?.[0]?.isIntersecting) {
    const id = props?.articles?.[0]?.id;
    BTE.trigger('articleInView', id);
  }
};

export const blogMapStateToProps = ({ article, liveBlog, router }) => ({
  flag: article?.content?.[0]?.flag ?? null,
  activeItems: liveBlog.activeItems,
  path: router.path,
  domain: router.domain,
  fetchingLatest: liveBlog.fetchingLatest,
  numberOfNewItems: liveBlog.numberOfNewItems,
  loadingMoreCards: liveBlog.loadingMoreCards,
  trueTotalItems: liveBlog.trueTotalItems,
  isRegularBlog: shouldRenderRegularBlog(article?.content?.[0]?.subType ?? null),
});

export const getDatePublished = (card) => {
  let datePublished;
  if (card) {
    const { date: { publishedAt } } = card;
    datePublished = dayjs(publishedAt).utc().format();
  }
  return datePublished;
};


export const getShowMoreBifurcatedMarkupEmbeds = (card) => {
  let visibleMarkupAndEmbeds = card.content.markupAndEmbeds;
  let hiddenMarkupAndEmbeds = [];

  const showMoreBreakpointIndex = card.content.markupAndEmbeds.findIndex(
    (markupAndEmbed) => (markupAndEmbed.widget?.properties?.embed?.type === 'SHOW_MORE_BUTTON' && markupAndEmbed.widget.properties.embed.displayShowMore),
  );
  const hasShowMoreBreakpoint = showMoreBreakpointIndex > -1;


  if (hasShowMoreBreakpoint) {
    visibleMarkupAndEmbeds = card.content.markupAndEmbeds.slice(
      0,
      showMoreBreakpointIndex + 1,
    );
    hiddenMarkupAndEmbeds = card.content.markupAndEmbeds.slice(
      showMoreBreakpointIndex + 1,
    );
  }
  return {
    ...card.content,
    visibleMarkupAndEmbeds,
    hiddenMarkupAndEmbeds,
    hasShowMoreBreakpoint,
  };
};

// exported for testing
export const widgetPresentationOverride = {
  size: 'medium',
  style: 'default',
  // i'm assuming the original `_override` and `_overrideSource` properties here were for making it
  // known to devs who may be confused where these presentation values were coming from (since
  // theoretically with these overrides the values from API would be different). leaving one here
  // but removing the filename one
  _overriddenByGetProcessedCardData: true,
};

export const getProcessedCardData = (item) => {
  const processed = item;
  const embeds = processed?.content?.markupAndEmbeds ?? [];

  // if headline or content null, default to empty data structure to allow for graceful rendering.
  processed.content = processed.content || { markupAndEmbeds: [], text: [] };
  processed.headline = processed.headline || {};

  processed.content.markupAndEmbeds = embeds.filter((embed) => !!embed)
    .map((embed) => {
      const element = embed;

      if (element.presentation) {
        // Override widget presentation configuration
        element.presentation = {
          ...element.presentation,
          ...widgetPresentationOverride,
        };
      } else if (element.type === 'markup' && element.element !== 'p') {
        // Non-paragraph elements do not have presentation
        // We will add our overrides anyway so components can choose to use
        element.presentation = widgetPresentationOverride;
      }

      return transformWidgetData(element);
    });

  if (processed.replies) {
    processed?.replies.map(getProcessedCardData);
  }

  return processed;
};

export function formatCardDataUrlPath({
  datePublished,
  taxonomyPath,
  queryLimit,
  articleId,
}) {
  const queryParams = new URLSearchParams({ taxonomyPath, size: queryLimit, articleId });

  if (datePublished) {
    queryParams.set('datePublished', datePublished.replace(/\+(\d){2}:(\d){2}$/, ''));
  }

  return `articlecards/search?${queryParams}`;
}

export function formatArticleCardsDataUrl(formatArgs) {
  const path = formatCardDataUrlPath(formatArgs);
  const bentoAPIRoute = getRamenBentoAPIUrl();
  return `${bentoAPIRoute}/${path}`;
}

export function processArticleCardsSearchData(json) {
  const rawCards = json?.data?.article?.cards?.items;
  if (Array.isArray(rawCards)) {
    return {
      ...json?.data.article,
      cards: {
        items: rawCards.map((item) => getProcessedCardData(item)),
      },
    };
  }
  return {
    ...json?.data?.article,
    cards: {
      items: [],
    },
  };
}

export function fetchLiveBlogCards(
  url,
  processCardData = processArticleCardsSearchData,
) {
  return fetch(url)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Error fetching live blog cards: ${res.status} ${res.statusText} ${res.url}`);
      }

      return res.json();
    })
    .then(processCardData);
}

export function fetchCardByID(id) {
  const bentoAPIRoute = getRamenBentoAPIUrl();
  return fetch(`${bentoAPIRoute}/card/${id}`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Error fetching card with ID ${id}: ${res.status} ${res.statusText} ${res.url}`);
      }
      return res.json();
    })
    .then((json) => json?.data?.card)
    .then((card) => getProcessedCardData(card));
}
export function fetchLatestLiveBlogCardsByTaxonomyPath({
  taxonomyPath,
  queryLimit = FIRST_PAGE_SIZE,
  articleId,
  datePublished,
}) {
  const endpoint = formatArticleCardsDataUrl({
    taxonomyPath,
    queryLimit,
    articleId,
    datePublished,
  });

  return fetchLiveBlogCards(endpoint);
}
