import PropTypes from 'prop-types';

import url from './url';

export default PropTypes.shape({
  copyright: PropTypes.string,
  externalUrl: PropTypes.string,
  name: PropTypes.string,
  primaryImage: PropTypes.shape({
    altText: PropTypes.string,
    caption: PropTypes.string,
    encodingFormat: PropTypes.string,
    graphicContent: PropTypes.bool,
    height: PropTypes.number,
    id: PropTypes.string,
    url,
    width: PropTypes.number,
  }),
});
