const transformations = {
  youtube(data) {
    return {
      type: 'embeddedWidget',
      widget: {
        ...data,
        name: 'youtubeplus',
      },
      presentation: {
        size: 'medium',
      },
    };
  },

  tweet_embed(data) {
    return {
      type: 'embeddedWidget',
      widget: {
        ...data,
        name: 'tweetplus_embed',
      },
      presentation: {
        size: 'medium',
      },
    };
  },

  facebook_embedded_post(data) {
    return {
      type: 'embeddedWidget',
      widget: {
        ...data,
        name: 'facebookplus_embedded_post',
        properties: {
          ...data.properties,
          'facebookplus-post-url': data.properties['post-url'],
        },
      },
      presentation: {
        size: 'medium',
      },
    };
  },

  instagram(data) {
    return {
      type: 'embeddedWidget',
      widget: {
        ...data,
        name: 'instagramplus',
      },
      presentation: {
        size: 'medium',
      },
    };
  },
};

/**
 * Transforms a limited set of non-standard embeddedWidget embeds.
 * @param {*} data
 */
export function transformWidgetData(data) {
  if (data.type !== 'embeddedWidget') return data;

  const transformed = transformations[data.widget.name]
    ? transformations[data.widget.name](data.widget)
    : data;

  // Decodes first-level string values
  const widget = Object.entries(transformed).reduce((accum, [key, val]) => {
    // disabling eslint since this is a new object being passed in to `reduce`
    accum[key] = val; // eslint-disable-line no-param-reassign
    if (typeof val === 'string') {
      try {
        accum[key] = decodeURIComponent(val); // eslint-disable-line no-param-reassign
      } catch (e) {
        // no-op; if any error threw above, the original assignment
        // shouldn't have been overwritten
      }
    }
    return accum;
  }, {});

  return widget;
}
