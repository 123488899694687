import PropTypes from 'prop-types';
import imageCard from './imageCard';

export default {
  actionLink: PropTypes.string,
  actionText: PropTypes.string,
  additionalClasses: PropTypes.string,
  cards: PropTypes.arrayOf(imageCard),
  headerText: PropTypes.string,
};
