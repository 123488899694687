/*
  this will be deleted once curation and fronts are finished
*/
import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  headline: PropTypes.shape({
    actual: PropTypes.string,
  }),
  title: PropTypes.shape({
    actual: PropTypes.string,
    tease: PropTypes.string,
    seo: PropTypes.string,
    social: PropTypes.string,
  }),
  caption: PropTypes.string,
  altText: PropTypes.string,
  url: PropTypes.shape({}),
  encodingFormat: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  byline: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  source: PropTypes.shape({
    name: PropTypes.string,
    copyright: PropTypes.string,
  }),
  customFields: PropTypes.shape({
    presentation: PropTypes.string,
    alignment: PropTypes.string,
  }),
});
