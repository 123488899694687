import PropTypes from 'prop-types';

import person from './person';

// Author is now an array of embedded persons.

export default PropTypes.shape({
  type: PropTypes.string,
  authorType: PropTypes.string, // comes from an enum
  featuredAuthor: PropTypes.shape({
    isFeaturedAuthor: PropTypes.bool,
    overrideTeaseImage: PropTypes.bool,
  }),
  person,
});
