import React from 'react';

const defaultValue = {
  domain: null,
  hostname: null,
  path: '/',
  statusCode: 200,
};

export const RouteContext = React.createContext(defaultValue);
