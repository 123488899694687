import PropTypes from 'prop-types';

export default PropTypes.shape({
  format: PropTypes.string,
  assetType: PropTypes.string,
  publicUrl: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  assetDuration: PropTypes.number,
  bitrate: PropTypes.number,
});
