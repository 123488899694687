import PropTypes from 'prop-types';

export default PropTypes.shape({
  newsletterTitle: PropTypes.string.isRequired,
  newsletterDescription: PropTypes.string.isRequired,
  includedTaxonomyPaths: PropTypes.string.isRequired,
  excludedTaxonomyPaths: PropTypes.string,
  newsletterKey: PropTypes.string.isRequired,
  newsletterSignupSource: PropTypes.string.isRequired,
});
