import PropTypes from 'prop-types';

import image from './image';
import headline from './headline';
import url from './url';
import slide from './slide';
import taxonomyCollection from './taxonomyCollection';


export default PropTypes.shape({
  slideshows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    headline,
    primaryImage: image,
    teaseImage: image,
    description: PropTypes.shape({
      primary: PropTypes.string,
      seo: PropTypes.string,
    }),
    dateCreated: PropTypes.string,
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
    url,
    breakingNews: PropTypes.bool,
    hidden: PropTypes.bool,
    nativeAd: PropTypes.bool,
    adsEnabled: PropTypes.bool,
    totalSlides: PropTypes.number,
    slides: PropTypes.arrayOf(slide),
    taxonomyCollection,
  })),
});
