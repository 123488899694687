import { createAction } from 'redux-actions';
import api from 'lib/api';

// Types
export const DISABLE_LAZYLOAD = 'shared/DISABLE_LAZYLOAD';
export const SET_ADS_ENABLED = 'shared/SET_ADS_ENABLED';
export const SET_CANONICAL_URL = 'shared/SET_CANONICAL_URL';
export const SET_CHROMELESS = 'shared/SET_CHROMELESS';
export const SET_EMBED_PKG = 'shared/SET_EMBED_PKG';
export const SET_LABEL = 'shared/SET_LABEL';
export const SET_MPS_CONFIG = 'shared/SET_MPS_CONFIG';
export const SET_ORIGINAL_CORRELATION_ID = 'shared/SET_ORIGINAL_CORRELATION_ID';
export const SET_PAGE_TYPE = 'shared/SET_PAGE_TYPE';
export const SET_SECTION = 'shared/SET_SECTION';
export const SET_SERIES = 'shared/SET_SERIES';
export const SET_SHOW = 'shared/SET_SHOW';
export const SET_SUB_TOPIC = 'shared/SET_SUB_TOPIC';
export const SET_TABOOLA_LOADED = 'shared/SET_TABOOLA_LOADED';
export const SET_TOPIC = 'shared/SET_TOPIC';
export const SET_QUERY = 'shared/SET_QUERY';
export const SET_VERTICAL = 'shared/SET_VERTICAL';
export const SOCIALMEDIA_LOAD_ERROR = 'shared/SOCIALMEDIA_LOAD_ERROR';
export const SOCIALMEDIA_LOAD_PENDING = 'shared/SOCIALMEDIA_LOAD_PENDING';
export const SOCIALMEDIA_LOAD_SUCCESS = 'shared/SOCIALMEDIA_LOAD_SUCCESS';
export const TABOOLA_FEED = 'shared/TABOOLA_FEED';
const SET_PLATFORM = 'shared/SET_PLATFORM';
export const SET_VIEW = 'shared/SET_VIEW';

// Actions
export const disableLazyLoadAction = createAction(DISABLE_LAZYLOAD);
export const setAdsEnabled = createAction(SET_ADS_ENABLED);
export const setCanonicalUrl = createAction(SET_CANONICAL_URL);
export const setChromeless = createAction(SET_CHROMELESS);
export const setEmbedPkg = createAction(SET_EMBED_PKG);
export const setLabel = createAction(SET_LABEL);
export const setMpsConfig = createAction(SET_MPS_CONFIG);
export const setOriginalCorrelationId = createAction(SET_ORIGINAL_CORRELATION_ID);
export const setPageView = createAction(SET_PAGE_TYPE);
export const setPlatform = createAction(SET_PLATFORM);
export const setSection = createAction(SET_SECTION);
export const setSeries = createAction(SET_SERIES);
export const setShow = createAction(SET_SHOW);
export const setSubtopic = createAction(SET_SUB_TOPIC);
export const setTaboolaFeed = createAction(TABOOLA_FEED);
export const setTaboolaLoaded = createAction(SET_TABOOLA_LOADED);
export const setTopic = createAction(SET_TOPIC);
export const setQuery = createAction(SET_QUERY);
export const setVertical = createAction(SET_VERTICAL);
export const setView = createAction(SET_VIEW);

export const loadSocialMedia = (vertical) => api({
  endpoint: `socialMedia/${vertical}`,
  types: [SOCIALMEDIA_LOAD_PENDING, SOCIALMEDIA_LOAD_SUCCESS, SOCIALMEDIA_LOAD_ERROR],
});

// Reducer
const INITIAL_STATE = {
  adsEnabled: true,
  canonicalUrl: null,
  disableLazyLoad: false,
  mpsConfig: {},
  isChromeless: false,
  label: null,
  originalCorrelationId: undefined,
  pageView: null,
  section: null,
  series: null,
  show: null,
  socialMediaProfiles: {},
  subTopic: null,
  taboolaFeed: false,
  taboolaLoaderLoaded: false,
  topic: null,
  query: {
    bento: null,
    curator: null,
  },
  vertical: null,
  view: null,
};

export function shared(currentState, { type, payload }) {
  const state = {
    ...INITIAL_STATE,
    ...currentState,
  };

  switch (type) {
    case SET_PAGE_TYPE:
      return {
        ...state,
        pageView: payload,
      };

    case SET_EMBED_PKG:
      return {
        ...state,
        packageEmbed: payload,
      };

    case SET_CANONICAL_URL:
      return {
        ...state,
        canonicalUrl: payload,
      };

    case SET_ORIGINAL_CORRELATION_ID:
      return {
        ...state,
        originalCorrelationId: payload,
      };

    case SET_TOPIC:
      return {
        ...state,
        topic: payload,
      };

    case SET_QUERY:
      return {
        ...state,
        query: payload,
      };

    case SET_VERTICAL:
      if (!payload) {
        return {
          ...state,
          vertical: 'news',
        };
      }

      return {
        ...state,
        vertical: payload,
      };

    case SET_SECTION:
      if (!payload) {
        return {
          ...state,
          section: '',
        };
      }

      return {
        ...state,
        section: payload,
      };

    case SET_SUB_TOPIC:
      if (!payload) {
        return {
          ...state,
          subTopic: '',
        };
      }

      return {
        ...state,
        subTopic: payload,
      };

    case SET_SERIES:
      if (!payload) {
        return {
          ...state,
          series: '',
        };
      }

      return {
        ...state,
        series: payload,
      };

    case SET_LABEL:
      if (!payload) {
        return {
          ...state,
          label: '',
        };
      }

      return {
        ...state,
        label: payload,
      };

    case SET_SHOW:
      if (!payload) {
        return {
          ...state,
          show: '',
        };
      }

      return {
        ...state,
        show: payload,
      };

    case SET_ADS_ENABLED:
      return {
        ...state,
        adsEnabled: payload,
      };

    case SOCIALMEDIA_LOAD_SUCCESS: {
      const {
        social: {
          socialMediaProfiles,
        },
      } = payload.data;

      return {
        ...state,
        socialMediaProfiles,
      };
    }

    case SET_CHROMELESS:
      return {
        ...state,
        isChromeless: true,
      };

    case SET_MPS_CONFIG:
      return {
        ...state,
        mpsConfig: payload,
      };

    case SET_TABOOLA_LOADED:
      return {
        ...state,
        taboolaLoaderLoaded: true,
      };

    case DISABLE_LAZYLOAD:
      return {
        ...state,
        disableLazyLoad: true,
      };

    case TABOOLA_FEED:
      return {
        ...state,
        taboolaFeed: true,
      };

    case SET_PLATFORM:
      return {
        ...state,
        platform: payload,
      };

    case SET_VIEW:
      return {
        ...state,
        view: payload,
      };

    default:
      return state;
  }
}
