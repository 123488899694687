import memoizeOne from 'memoize-one';

const getLayouts = (curation) => (curation?.layouts) || [];

/**
 * Get all packages from a curation in one array. Getting all packages will be memoized in order to
 * reduce computation time for successive calls. Since we currently use redux, this should always be
 * the same `curation` object (object identity) as long as you're passing the `curation` directly
 * from redux state, and not doing any intermediary manipulation.
 *
 * @param {Curation} curation Curation data (from redux).
 * @return {Package[]} Array of packages. The full packages are returned, so you can do further
 * mapping/data manipulation/etc. These Packages do not include {@link CurationPackageContext}
 * properties, as they are the Packages directly from the redux store. See
 * {@link CurationPackageContent}
 */
export const getAllPackages = memoizeOne(
  (curation) => getLayouts(curation)
    .flatMap((layout) => (layout?.packages) || []),
);

/**
 * `getAllPackageTypes` returns a Set containing all package types in a curation. It is memoized so it
 * should theretically return the same set given the same Curation (object identity) passed in.
 * Curation ids don't update when making updates in curator (until published), so memoizing with
 * object identity should solve any problems with data updates.
 *
 * @param  {Curation} curation Curation data (from redux).
 * @return {Set<string>} Set of all package types in a curation.
 */
export const getAllPackageTypes = memoizeOne(
  (curation) => {
    const packagesSet = new Set();
    getAllPackages(curation)
      .forEach((pkg) => {
        if (pkg?.type) {
          packagesSet.add(pkg.type);
        }
      });
    return packagesSet;
  },
);

export const getAllPackageSubTypes = memoizeOne(
  (curation) => {
    const packagesSet = new Set();
    getAllPackages(curation)
      .forEach((pkg) => {
        if (pkg?.subType) {
          packagesSet.add(pkg.subType);
        }
      });
    return packagesSet;
  },
);

export const curationContainsItemsBySource = (curation, source) => {
  // Check at least one item contains the given source
  const packageContainsItemsBySource = (pkg) => {
    const items = pkg?.items ?? [];
    return items.some(
      (item) => (item?.item?.source?.organization?.name) === source,
    );
  };

  // Check at least one package contains matching source
  const layoutContainsItemBySource = (layout) => {
    const packages = layout?.packages ?? [];
    return packages.some(packageContainsItemsBySource);
  };

  return getLayouts(curation).some(layoutContainsItemBySource);
};

export const getPackagesByType = (curation, type) => {
  const found = [];
  getLayouts(curation)
    .forEach((layout) => {
      const packages = layout?.packages ?? [];
      found.push(...packages.filter((pkg) => pkg.type === type));
    });
  return found;
};
