/**
 * Checks if user is in a disconnected state where they have provided permission for notifications
 * but subsequently become opted out at Airship, re-registers them in this case without re-prompting
 * @param  {sdk} sdk
 * @returns {boolean}
 */
function reOptInUsersWithExistingPermission(sdk) {
  const { channel = null, permission = null } = sdk || {};
  const { channel_id: channelId = null, opt_in: optIn = false } = channel || {};
  return permission === 'granted' && (!channel || (channelId && !optIn));
}

export {
  reOptInUsersWithExistingPermission,
};
