import PropTypes from 'prop-types';

import image from './image';
import playmakerMetadata from './playmakerMetadata';
import video from './video';

export const primaryMedia = PropTypes.oneOfType([
  image,
  playmakerMetadata,
  video,
]);
