import PropTypes from 'prop-types';

import organization from './organization';

export default PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  publisher: organization,
  name: PropTypes.string,
  baseline: PropTypes.string,
  mobileBaseline: PropTypes.string,
  fallbackUrl: PropTypes.string,
  properties: PropTypes.object,
  dateCreated: PropTypes.string,
  dateModified: PropTypes.string,
});
