import PropTypes from 'prop-types';
import image from './image';
import headline from './headline';
import author from './author';
import organization from './organization';

export default PropTypes.shape({
  headline,
  authors: PropTypes.arrayOf(author),
  source: organization,
  caption: PropTypes.arrayOf(PropTypes.object),
  image,
});
