/* eslint-disable no-param-reassign */

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  ALL, AMOUNT_TO_DISPLAY_NEWS, RECOMMENDATIONS_AMOUNT, UNAUTHENTICATED,
} from 'lib/myNewsConstants';
import { produce } from 'immer';
import { api } from './utils';

export const INITIAL_STATE = {
  user: {},
  authenticationState: '',
  bookmarks: {},
  filterType: ALL,
  customer: {
    zipCode: '',
    customerId: '',
    mparticleId: '',
    history: {
      data: [],
      pageInfo: {
        nextPage: null,
      },
    },
    bookmarks: {
      data: [],
      pageInfo: {
        nextPage: null,
      },
    },
    recommendations: {
      data: [],
    },
  },
  loading: true,
};

export const store = (set, get) => ({
  ...INITIAL_STATE,

  /**
   * Authenticates the user using the HFSapi identity service.

   * @param {Object} options - The options for the authentication.
   * @param {string} options.defaultPage - The default page to redirect to after authentication.
   */
  authenticate: ({ defaultPage } = {}) => {
    window?.HFSapi?.identity?.authenticate({ defaultPage });
  },

  /**
   * Updates the store with the new authentication state,
   * user data and token.
   */
  setAuthentication: () => {
    const { authenticationState, user } = window.HFSapi.identity;
    set(
      {
        authenticationState,
        user,
      },
      false,
      'setAuthentication',
    );
  },

  /**
   * Logs out the user by resetting the authentication state, user data, and token in the store to their initial values.
   */
  resetAuthentication: () => {
    set(
      {
        authenticationState: UNAUTHENTICATED,
        bookmarks: INITIAL_STATE.bookmarks,
        customer: INITIAL_STATE.customer,
        token: INITIAL_STATE.token,
        user: INITIAL_STATE.user,
      },
      false,
      'resetAuthentication',
    );
  },

  /**
   * Resets the my news data in the store to its initial state.
   */
  reset: () => set({ ...INITIAL_STATE }, false, 'reset'),

  /**
   * Makes an API call to retrieve the customer data.
   *
   * @async
   */
  getCustomer: async () => {
    const response = await api('/mynewsapi/customer/');
    set({
      customer: {
        ...get().customer,
        ...response.data.getCustomer,
      },
    }, false, 'getCustomer');
  },

  /**
   * Sets the loading state in the store.
   *
   * @param {boolean} loading - The loading state.
   */
  setLoading: (loading) => set({ loading }, false, 'setLoading'),

  /**
   * Sets the filter type in the store.
   *
   * @param {string} filterType - The filter type. Equals the content type.
   * Can be 'all', 'article', 'video', 'recipe', 'product'.
   */
  setFilterType: (filterType) => set({ filterType }, false, 'setFilterType'),

  /**
   * Retrieves the user's bookmarks from the server.
   * This is used for the mynews and accounts section.
   *
   * @param {Object} params - The parameters for the get operation.
   * @param {number} params.size - The number of bookmarks to retrieve.
   * @param {string} params.cursor - The cursor for pagination.
   * @param {string} params.filterType - The filter type. Equals the content type.
   * Can be 'all', 'article', 'video', 'recipe', 'product'.
   * @param {boolean} params.append - Whether to append the new data to the existing data.
   * @async
   */
  getBookmarks: async ({
    size = AMOUNT_TO_DISPLAY_NEWS,
    cursor = null,
    filterType = ALL,
  } = {}) => {
    set({ loading: true });
    const response = await api(`/mynewsapi/bookmarks/${size}/${cursor}/${filterType}`);
    const {
      data: {
        getCustomer: { bookmarks },
      },
    } = response;
    if (cursor) {
      set(
        {
          customer: {
            ...get().customer,
            bookmarks: {
              ...get().customer.bookmarks,
              data: get().customer.bookmarks.data.concat(bookmarks.data),
              pageInfo: bookmarks.pageInfo,
            },
          },
          filterType,
          loading: false,
        },
        false,
        'getBookmarks',
      );
    } else {
      set(
        {
          customer: { ...get().customer, bookmarks },
          filterType,
          loading: false,
        },
        false,
        'getBookmarks',
      );
    }
  },

  /**
   * Makes an API call to save the customer's zip code.
   *
   * @async
   * @param {Object} params - The parameters for the save operation.
   * @param {string} params.zipCode - The zip code to save.
   */
  saveCustomer: async ({ zipCode } = {}) => {
    if (!zipCode) return;
    await api(
      `/mynewsapi/savecustomer/${zipCode}`,
      // mparticleId could be undefined if users are using ad blockers
      { mparticleId: get().customer.mparticleId },
    );
    await get().getCustomer();
  },

  /**
   * Retrieves a list of recommended items for the user from the server and updates the store with the result.
   *
   * @async
   * @param {Object} params - The parameters for the get operation.
   * @param {number} params.size - The number of recommendations to retrieve.
   */
  getRecommendations: async ({ size = RECOMMENDATIONS_AMOUNT } = {}) => {
    const response = await api(`/mynewsapi/recommendations/${size}`);
    set(produce((draft) => {
      draft.customer.recommendations.data = response.data.getCustomer.recommendations.data;
    }), false, 'getRecommendations');
  },

  /**
   * Retrieves the user's search history from the server and updates the store with the result.
   *
   * @async
   * @param {Object} params - The parameters for the get operation.
   * @param {number} params.size - The number of search history items to retrieve.
   * @param {string} params.cursor - The cursor for pagination.
   */
  getHistory: async ({ size = AMOUNT_TO_DISPLAY_NEWS, cursor = null } = {}) => {
    const response = await api(`/mynewsapi/history/${size}/${cursor}`);
    set(produce((draft) => {
      // Merge new data with existing data
      if (cursor) {
        draft.customer.history.data = [
          ...draft.customer.history.data,
          ...response.data.getCustomer.history.data,
        ];
      } else {
        draft.customer.history.data = response.data.getCustomer.history.data;
      }
      // Update pageInfo
      draft.customer.history.pageInfo = response.data.getCustomer.history.pageInfo;
    }), false, 'getHistory');
  },

  /**
   * Saves the given content ID to the user's search history.
   *
   * @async
   * @param {Object} params - The parameters for the get operation.
   * @param {string} params.contentId - The ID of the content to save.
   * @param {string} params.contentType - The type of the content to save.
   */
  saveHistory: async ({
    contentType,
    contentId,
  }) => {
    set({ savedContentId: contentId }, false, 'saveHistory');
    const response = await api(
      `/mynewsapi/savehistory/${contentId}/${contentType}`,
      // mparticleId could be undefined if users are using ad blockers
      { mparticleId: get().customer.mparticleId },
    );
    return response.data.saveHistory;
  },

  /**
   * Creates a bookmark for the given content ID and content type.
   *
   * @async
   * @param {Object} params - The parameters for the create operation.
   * @param {string} params.contentId - The ID of the content to create the bookmark for.
   * @param {string} params.contentType - The type of the content to create the bookmark for.
   */
  createBookmark: async ({ contentId, contentType }) => {
    set(
      (state) => ({
        bookmarks: { ...state.bookmarks, [contentId]: true },
      }),
      false,
      'createBookmarkOptimisticUpdate',
    );
    const response = await api(
      `/mynewsapi/createbookmark/${contentId}/${contentType}`,
      // mparticleId could be undefined if users are using ad blockers
      { mparticleId: get().customer.mparticleId },
    );
    set(
      (state) => ({
        bookmarks: { ...state.bookmarks, [contentId]: response.data.createBookmark },
      }),
      false,
      'createBookmark',
    );
  },

  /**
   * Deletes a bookmark for the given content ID.
   *
   * @async
   * @param {string} contentId - The ID of the content to delete the bookmark for.
   */
  deleteBookmark: async (contentId) => {
    set(
      (state) => ({
        bookmarks: { ...state.bookmarks, [contentId]: false },
      }),
      false,
      'deleteBookmarkOptimisticUpdate',
    );

    const response = await api(`/mynewsapi/deleteBookmark/${contentId}/`);

    // Api returns true if bookmark was deleted successfully
    const wasDeleted = response.data.deleteBookmark;

    // If the bookmark was not deleted, get the current state from the server
    if (!wasDeleted) return get().isContentBookmarked(contentId);

    return set(produce((draft) => {
      // remove from bookmarks
      draft.bookmarks[contentId] = false;
      // remove from customer bookmarks if it exists
      draft.customer.bookmarks.data = draft.customer.bookmarks.data.filter(
        (bookmark) => bookmark.contentId !== contentId,
      );
    }, false, 'deleteBookmark'));
  },

  /**
   * Checks with the api whether the given content ID is bookmarked and updates the store with the result.
   *
   * @async
   * @param {string} contentId - The ID of the content to check for bookmarking.
   */
  isContentBookmarked: async (contentId) => {
    const response = await api(`/mynewsapi/iscontentbookmarked/${contentId}/`);
    set(
      (state) => ({
        bookmarks: {
          ...state.bookmarks,
          [contentId]: response.data.getBookmarkByContentId,
        },
      }),
      false,
      'isContentBookmarked',
    );
  },
});


/**
 * Enable the devtools in development mode only
 */

const isDevelop = process.env.NODE_ENV === 'development';

const devToolsProps = {
  name: 'useMyNews',
  anonymousActionType: 'action',
  serialize: true,
  actionSanitizer: (action) => ({
    ...action,
    type: `MyNews/${action.type}`,
  }),
};

const middlewares = (f) => (isDevelop ? devtools(f, devToolsProps) : f);


/**
 * Create Store
 */

export const useMyNewsStore = create(middlewares(store));
