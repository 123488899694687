import api from 'lib/api';
import { serialize } from 'lib/urlUtils';

export const SEARCH_PENDING = 'product/SEARCH_PENDING';
export const SEARCH_SUCCESS = 'product/SEARCH_SUCCESS';
export const SEARCH_ERROR = 'product/SEARCH_ERROR';

export const searchProductsAction = (searchCriteria) => api({
  endpoint: `products/search${serialize(searchCriteria, false)}`,
  types: [SEARCH_PENDING, SEARCH_SUCCESS, SEARCH_ERROR],
});

export const INITIAL_STATE = {
  items: [],
  loading: false,
  error: null,
};

export function product(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SEARCH_PENDING:
      return {
        ...state,
        loading: true,
      };

    case SEARCH_SUCCESS: {
      return {
        ...state,
        items: payload?.data?.search?.items ?? [],
        loading: false,
      };
    }

    case SEARCH_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
}
