import React from 'react';
import { shallow } from 'zustand';

/**
 * A higher-order component that injects a store into a component's props.
 * We need this to use Zustand with React Class components.
 * @param {function} Component - The component to wrap.
 * @param {function} useStore - A hook that returns the store.
 * @param {function} selectorFn - A function that selects the desired state from the store.
 */
export function withStore(Component, useStore, selectorFn) {
  return function WrappedComponent(props) {
    const myStore = useStore(selectorFn, shallow);
    return <Component {...props} {...myStore} />;
  };
}
