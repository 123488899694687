import PropTypes from 'prop-types';

export default PropTypes.shape({
  type: PropTypes.string,
  hash: PropTypes.string,
  url: PropTypes.stirng,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  duration: PropTypes.number,
  score: PropTypes.number,
});
