import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { HIDE_GLOBAL_MENU_NAV } from 'lib/brandFeatures';

/**
 * Uses config and feature flags to determine if single nav should be true
 * Vertical is used to get the config, which can be set to true or false,
 * or an object with a property called useFeatureFlag.
 * if useFeatureFlag is true, return value of featureFlagContext
 * otherwise return the config value, with config object existing counts as true
 * @param {string} vertical
 * @param {boolean} featureFlagContext - feature flag for hiding global nav, set by Akamai
 * @returns boolean
 */
export const hasSingleNav = (vertical, featureFlagContext) => {
  const globalHiddenConf = getFeatureConfigForBrand(HIDE_GLOBAL_MENU_NAV, vertical);
  const useFeatureFlag = globalHiddenConf?.useFeatureFlag ?? false;
  if (useFeatureFlag && typeof featureFlagContext !== 'undefined') {
    return featureFlagContext;
  }
  return !!globalHiddenConf;
};
