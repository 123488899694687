import { createContext, useContext } from 'react';

/**
 * @type {React.Context<import('lib/Hooks/useLiveBlogCards').LiveBlogCardsState>}
 */
export const LiveBlogCardsContext = createContext();

/**
 * @returns {import('lib/Hooks/useLiveBlogCards').LiveBlogCardsState}
 */
export function useLiveBlogCardsContext() {
  return useContext(LiveBlogCardsContext);
}
