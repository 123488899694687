import { createAction } from 'redux-actions';
import api from 'lib/api';
import { hasSingleNav } from 'lib/hasSingleNav';

export const ACTIVATE = 'navbar/ACTIVATE';
export const DEACTIVATE = 'navbar/DEACTIVATE';
export const SET_THEME = 'navbar/SET_THEME';
export const SET_HEADLINE = 'navbar/SET_HEADLINE';
export const SET_SHORTCUT_VISIBLE = 'navbar/SET_SHORTCUT_VISIBLE';
export const BULK_UPDATE = 'navbar/BULK_UPDATE';
export const SET_INITIAL_LOCAL = 'navbar/SET_INITIAL_LOCAL';
export const SET_INITIAL_THEME = 'navbar/SET_INITIAL_THEME';
export const SET_DURATION = 'navbar/SET_DURATION';
const MINI_PLAYER_LOAD_PENDING = 'navbar/MINI_PLAYER_LOAD_PENDING';
const MINI_PLAYER_LOAD_COMPLETE = 'navbar/MINI_PLAYER_LOAD_COMPLETE';
const MINI_PLAYER_LOAD_FAILED = 'navbar/MINI_PLAYER_LOAD_FAILED';

export const MOST_POPULAR_STORIES_LOAD_PENDING = 'navbar/MOST_POPULAR_STORIES_LOAD_PENDING';
export const MOST_POPULAR_STORIES_LOAD_FAILED = 'navbar/MOST_POPULAR_STORIES_LOAD_FAILED';
export const MOST_POPULAR_STORIES_LOAD_COMPLETE = 'navbar/MOST_POPULAR_STORIES_LOAD_COMPLETE';
export const MOST_POPULAR_SHOP_LOAD_COMPLETE = 'navbar/MOST_POPULAR_SHOP_LOAD_COMPLETE';
export const MOST_POPULAR_RECIPE_LOAD_COMPLETE = 'navbar/MOST_POPULAR_RECIPE_LOAD_COMPLETE';

export const activate = createAction(ACTIVATE);
export const deactivate = createAction(DEACTIVATE);
export const setTheme = createAction(SET_THEME);
export const setDuration = createAction(SET_DURATION);
export const setHeadline = createAction(SET_HEADLINE);
export const setShortcutVisible = createAction(SET_SHORTCUT_VISIBLE);
export const bulkUpdate = createAction(BULK_UPDATE);
export const setInitialTheme = createAction(SET_INITIAL_THEME);
export const setInitialNavbarLocal = createAction(SET_INITIAL_LOCAL);
/**
 * Retrieves mini player data for a given vertical.
 *
 * @param {string} vertical - The vertical for which to retrieve mini player data.
 * @returns {object} The API response containing mini player data.
 */
export const getMiniPlayerData = (vertical) => api({
  endpoint: `miniPlayerTease/${vertical}`,
  types: [
    MINI_PLAYER_LOAD_PENDING,
    MINI_PLAYER_LOAD_COMPLETE,
    MINI_PLAYER_LOAD_FAILED,
  ],
  api: 'services',
});
/**
 * Retrieves the two latest items for a given curation type, shop or stories
 *
 * @param {object} options - Options object containing id and type.
 * @param {string} options.id - The id of the curated list to retrieve.
 * @param {string} options.type - The type of curated list to retrieve ('shop' or otherwise).
 * @returns {object} The API response containing the curated list data.
 */
export const loadMostPopularCuratedList = ({ id, type }) => {
  const loadComplete = type === 'shop'
    ? MOST_POPULAR_SHOP_LOAD_COMPLETE
    : MOST_POPULAR_STORIES_LOAD_COMPLETE;
  return api({
    endpoint: `curatedList/${id}/2`,
    types: [
      MOST_POPULAR_STORIES_LOAD_PENDING,
      loadComplete,
      MOST_POPULAR_STORIES_LOAD_FAILED,
    ],
  });
};
/**
 * Retrieves 2 most popular recipes
 *
 * @returns {object} The API response containing the most popular recipes data.
 */
export const loadMostPopularRecipes = () => {
  const filters = ['type:recipe', 'NOT taxonomy:today/recipeCategory/shoppable'];
  return api({
    endpoint: `/mostPopularRecipes/taxonomy?filters=${filters.join(' AND ')}`,
    types: [
      MOST_POPULAR_STORIES_LOAD_PENDING,
      MOST_POPULAR_RECIPE_LOAD_COMPLETE,
      MOST_POPULAR_STORIES_LOAD_FAILED,
    ],
  });
};

// Reducer
const INITIAL_STATE = {
  theme: 'light',
  setDuration: null,
  hasGlobalInsert: false,
  hasLocalInsert: false,
  headline: null,
  active: false,
  shortcutsVisible: true,
  shareUrl: null,
  miniPlayerLoading: false,
  miniPlayerLoaded: false,
  miniplayerError: false,
  miniPlayerData: {
    showTease: false,
  },
  read: [],
  shop: [],
  recipe: [],
  loading: false,
  error: null,
};

/**
 *
 * @param {*} state
 * @param {*} param1
 * @returns {object}
 */
export const navbar = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTIVATE:
      return {
        ...state,
        active: true,
      };

    case DEACTIVATE:
      return {
        ...state,
        active: false,
      };

    case SET_THEME:
      return {
        ...state,
        theme: payload,
      };

    case SET_DURATION:
      return {
        ...state,
        localAnimationDuration: payload,
      };

    case SET_HEADLINE:
      return {
        ...state,
        headline: payload,
      };

    case SET_SHORTCUT_VISIBLE:
      return {
        ...state,
        shortcutsVisible: payload,
      };

    case BULK_UPDATE:
      return {
        ...state,
        ...payload,
      };

    case SET_INITIAL_LOCAL: {
      // if singleNav, then we have a local insert
      const { vertical, featureFlagQueryParam } = payload;
      const hasLocalInsert = hasSingleNav(vertical, featureFlagQueryParam);
      return {
        ...state,
        hasLocalInsert,
      };
    }

    case SET_INITIAL_THEME: {
      let theme = 'light';
      if (['msnbc', 'noticias', 'deportes'].includes(payload)) {
        theme = 'vertical';
      } else if (payload === 'today') {
        theme = 'transparent';
      }
      return {
        ...state,
        theme,
      };
    }

    case MINI_PLAYER_LOAD_PENDING: {
      return {
        ...state,
        miniPlayerLoading: true,
      };
    }

    case MINI_PLAYER_LOAD_COMPLETE: {
      return {
        ...state,
        miniPlayerLoading: false,
        miniPlayerLoaded: true,
        miniPlayerData: payload.data,
      };
    }

    case MINI_PLAYER_LOAD_FAILED: {
      return {
        ...state,
        miniPlayerLoading: false,
        miniPlayerLoaded: true,
        miniplayerError: true,
      };
    }
    case MOST_POPULAR_STORIES_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      };

    case MOST_POPULAR_STORIES_LOAD_COMPLETE: {
      const {
        data: {
          curatedList: {
            content: { items },
          },
        },
      } = payload;

      const contentType = 'article';
      const dataActivityMap = 'trending-nav-general';

      items.forEach((trendingItem, i) => {
        const {
          headline,
          url: { primary: url },
        } = trendingItem;
        items[i] = {
          ...trendingItem,
          url,
          headline: headline.primary || headline.tease,
          contentType,
        };
      });

      const read = {
        items: [...items],
        title: 'What to Read:',
        dataActivityMap,
      };
      return {
        ...state,
        mostPopular: {
          ...state.mostPopular,
          read: { ...read },
        },
        loading: false,
      };
    }

    case MOST_POPULAR_SHOP_LOAD_COMPLETE: {
      const {
        data: {
          curatedList: {
            content: { items },
          },
        },
      } = payload;

      const contentType = 'article';
      const dataActivityMap = 'trending-nav-shop';

      items.forEach((trendingItem, i) => {
        const {
          headline,
          url: { primary: url },
        } = trendingItem;
        items[i] = {
          ...trendingItem,
          url,
          headline: headline.primary || headline.tease,
          contentType,
        };
      });

      const shop = {
        items: [...items],
        title: 'What to Shop:',
        dataActivityMap,
      };
      return {
        ...state,
        mostPopular: {
          ...state.mostPopular,
          shop,
        },
        loading: false,
      };
    }

    case MOST_POPULAR_RECIPE_LOAD_COMPLETE: {
      const {
        data: {
          search: { items },
        },
      } = payload;

      const contentType = 'recipe';
      const dataActivityMap = 'trending-nav-recipe';

      items.forEach((trendingItem, i) => {
        const {
          headline: { primary: primaryHeadline },
          url: { primary: url },
        } = trendingItem;


        items[i] = {
          ...trendingItem,
          url,
          headline: primaryHeadline,
          contentType,
        };
      });

      const cook = {
        items: [...items],
        title: 'What to Cook:',
        dataActivityMap,
      };

      return {
        ...state,
        mostPopular: {
          ...state.mostPopular,
          recipe: cook,
        },
        loading: false,
      };
    }

    case MOST_POPULAR_STORIES_LOAD_FAILED: {
      return {
        ...state,
        error: true,
      };
    }

    default:
      return state;
  }
};
