// Reducer
const INITIAL_STATE = {
  slots: [],
  articleHeight: 50,
};

export function adTest(state = INITIAL_STATE) {
  return state;
}
/* eslint-enable no-case-declarations */
