import { createAction } from 'redux-actions';
import getConfig from 'next/config';

import { parseOnClient } from 'lib/urlUtils';
import { getRamenBentoAPIUrl } from 'lib/getRamenBentoAPIUrl';

const getResponse = ({
  baseUrl, endpoint, query, additionalRequestData,
}) => {
  const { headers = {}, requestBody = {} } = additionalRequestData;
  const hasHeaders = Object.keys(headers).length;
  const hasRequestBody = Object.keys(requestBody).length;
  const { ...body } = requestBody;

  if (hasHeaders && hasRequestBody) {
    return fetch(
      `${baseUrl}/${endpoint}${query}`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify({ ...body }),
        // We do not want to include the cookies with api calls
        credentials: 'omit',
      },
    );
  }

  if (hasHeaders && !hasRequestBody) {
    return fetch(`${baseUrl}/${endpoint}${query}`, {
      headers,
      credentials: 'omit',
    });
  }

  return fetch(`${baseUrl}/${endpoint}${query}`);
};

// Returns a Thunk
export default function Api(
  {
    endpoint,
    types,
    additionalActionTypeData,
    additionalRequestData = {},
    api,
    cacheKey,
    failedValidationCallback = null,
    modifyResponse,
  },
) {
  return async (dispatch, getState) => {
    if (failedValidationCallback) {
      return failedValidationCallback(dispatch);
    }

    // getConfig is undefined until after next is instantiated
    // moving inside the function so this file works properly in tests
    const {
      serverRuntimeConfig: {
        API_URL_MYNEWS_SERVER,
        API_URL_SERVICES_SERVER,
      },
      publicRuntimeConfig: {
        API_URL_MYNEWS_PUBLIC,
        API_URL_SERVICES_PUBLIC,
      },
    } = getConfig();

    const state = getState();
    const [PENDING, SUCCESS, ERROR] = types;

    const pending = createAction(PENDING);
    const success = createAction(SUCCESS);
    const error = createAction(ERROR);

    dispatch(pending(endpoint));

    try {
      // use server side urls if defined, otherwise use the public urls
      let API_URL = getRamenBentoAPIUrl();
      if (types[0].includes('MYNEWS')) {
        API_URL = API_URL_MYNEWS_SERVER || API_URL_MYNEWS_PUBLIC;
      }
      const API_URL_SERVICES = API_URL_SERVICES_SERVER || API_URL_SERVICES_PUBLIC;

      let baseUrl = API_URL;
      if (!!api && api === 'services') {
        baseUrl = API_URL_SERVICES;
      }

      // Use client path if resource is requested from client side
      const parsedUrl = parseOnClient(baseUrl);
      if (parsedUrl.pathname) {
        const {
          hostname, pathname, port, protocol,
        } = parsedUrl;
        baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}${pathname}`;
      }

      const queryParams = [];

      // Handle BENTO API variable override in query string parameter
      if (!api && state.shared?.query?.bento) {
        queryParams.push(`bento=${state.shared.query.bento}`);

        if (state.shared.query.curator) {
          queryParams.push(`curator=${state.shared.query.curator}`);
        }
      }

      const endpointContainsQueryString = endpoint.includes('?');
      const query = queryParams.length
        ? `${endpointContainsQueryString ? '&' : '?'}${queryParams.join('&')}`
        : '';

      const { originalCorrelationId } = state.shared;

      const response = await getResponse({
        additionalRequestData: {
          ...additionalRequestData,
          headers: {
            ...additionalRequestData.headers,
            'x-original-correlation-id': originalCorrelationId,
          },
        },
        baseUrl,
        endpoint,
        query,
      });

      if (response.status >= 400) { // catch 4xx & 5xx errors
        const message = `${response.statusText} (${response.status}) @ ${response.url}`;

        return dispatch(error({ message, status: response.status, url: response.url }));
      }

      const json = await response.json();
      if (modifyResponse) {
        const modifiedJson = await modifyResponse(json);
        return dispatch(success({ ...modifiedJson, additionalActionTypeData, cacheKey }));
      }
      return dispatch(success({ ...json, additionalActionTypeData, cacheKey }));
    } catch (e) {
      return dispatch(error(e.message));
    }
  };
}
