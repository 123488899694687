import PropTypes from 'prop-types';

export default PropTypes.shape({
  marqueeType: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.shape({
    element: PropTypes.string,
    html: PropTypes.string,
    linkobject: PropTypes.bool,
    type: PropTypes.string,
  })),
  item: PropTypes.shape({
    url: PropTypes.shape({
      canonical: PropTypes.string,
    }),
  }),
  embed: PropTypes.shape({
    url: PropTypes.string,
  }),
  hidden: PropTypes.bool,
});
