import PropTypes from 'prop-types';

export default PropTypes.shape({
  brand: PropTypes.string,
  excludedPages: PropTypes.string,
  fallbackHeadline: PropTypes.string,
  fallbackTeaseImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
    }),
  ]),
  guid: PropTypes.string,
  headline: PropTypes.string,
  isFlagged: PropTypes.bool,
  includedPageTypes: PropTypes.shape({
    article: PropTypes.bool,
    cover: PropTypes.bool,
    front: PropTypes.bool,
  }),
  lastUpdated: PropTypes.string,
  staticTeaseImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
    }),
  ]),
  url: PropTypes.string,
  useAnimatedTease: PropTypes.bool,
});
