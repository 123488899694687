import PropTypes from 'prop-types';
import offer from '../offer';

const productDescription = PropTypes.shape({
  type: PropTypes.string,
  element: PropTypes.string,
  html: PropTypes.string,
});

const taxonomy = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  path: PropTypes.string,
});

const publisher = PropTypes.shape({
  name: PropTypes.string,
});

export default PropTypes.shape({
  id: PropTypes.string,
  datePublished: PropTypes.string,
  dateModified: PropTypes.string,
  dateCreated: PropTypes.string,
  breakingNews: PropTypes.bool,
  name: PropTypes.string,
  nativeAd: PropTypes.bool,
  offers: PropTypes.arrayOf(offer),
  productDescription: PropTypes.arrayOf(productDescription),
  publisher,
  sponsoredBy: PropTypes.string,
  taxonomy: PropTypes.shape({
    labels: PropTypes.arrayOf(taxonomy),
    commerce: PropTypes.arrayOf(taxonomy),
  }),
  teaseImage: PropTypes.shape({
    url: PropTypes.shape({
      primary: PropTypes.string,
    }),
  }),
});
