import { createAction } from 'redux-actions';
import api from 'lib/api';
import log from 'lib/log';

// Types
export const LAYOUT_LOAD_PENDING = 'layout/LAYOUT_LOAD_PENDING';
export const LAYOUT_LOAD_ERROR = 'layout/LAYOUT_LOAD_ERROR';

export const LAYOUT_LOAD_HEADER_SUCCESS = 'layout/LAYOUT_LOAD_HEADER_SUCCESS';
export const LAYOUT_LOAD_FOOTER_SUCCESS = 'layout/LAYOUT_LOAD_FOOTER_SUCCESS';

const LAYOUT_LOAD_NAV_PENDING = 'layout/LAYOUT_LOAD_NAV_PENDING';
const LAYOUT_LOAD_NAV_FAILED = 'layout/LAYOUT_LOAD_NAV_FAILED';
const LAYOUT_LOAD_NAV_SUCCESS = 'layout/LAYOUT_LOAD_NAV_SUCCESS';

export const LAYOUT_SET_TRIGGER_TOP = 'layout/LAYOUT_SET_TRIGGER_TOP';

// Actions
export const loadHeader = (vertical) => api({
  endpoint: `layout/${vertical}/header`,
  types: [LAYOUT_LOAD_PENDING, LAYOUT_LOAD_HEADER_SUCCESS, LAYOUT_LOAD_ERROR],
  api: 'services',
});

export const loadFooter = (vertical) => api({
  endpoint: `layout/${vertical}/footer`,
  types: [LAYOUT_LOAD_PENDING, LAYOUT_LOAD_FOOTER_SUCCESS, LAYOUT_LOAD_ERROR],
  api: 'services',
});

export const setTriggerTop = createAction(LAYOUT_SET_TRIGGER_TOP);

export const loadNavigation = (vertical) => api({
  endpoint: `navigation/${vertical}`,
  types: [LAYOUT_LOAD_NAV_PENDING, LAYOUT_LOAD_NAV_SUCCESS, LAYOUT_LOAD_NAV_FAILED],
});


// Reducer
const INITIAL_STATE = {
  header: {
    html: [],
  },
  footer: {
    html: [],
  },
  menu: {
    html: [],
  },
  triggerTop: 0,
  navigation: {
    globalMenu: {
      menuItems: [],
    },
    localMenu: {
      menuItems: [],
    },
  },
};

export function layout(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case LAYOUT_LOAD_HEADER_SUCCESS:
      return {
        ...state,
        header: payload,
      };

    case LAYOUT_LOAD_FOOTER_SUCCESS:
      return {
        ...state,
        footer: payload,
      };

    case LAYOUT_LOAD_ERROR:
      log.error(payload);
      return state;

    case LAYOUT_SET_TRIGGER_TOP:
      return {
        ...state,
        triggerTop: payload,
      };

    case LAYOUT_LOAD_NAV_SUCCESS: {
      return {
        ...state,
        navigation: {
          global: payload?.data?.navigation?.global ?? [],
          local: payload?.data?.navigation?.local ?? [],
        },
      };
    }

    default:
      return state;
  }
}
