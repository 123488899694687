import { createAction } from 'redux-actions';

import api from 'lib/api';

// Types
export const TVE_LOAD_FEATURED_PENDING = 'tve/LOAD_FEATURED_PENDING';
const TVE_LOAD_FEATURED_SUCCESS = 'tve/LOAD_FEATURED_SUCCESS';
const TVE_LOAD_FEATURED_ERROR = 'tve/LOAD_FEATURED_ERROR';

export const TVE_LOAD_OPTIONS_PENDING = 'tve/LOAD_OPTIONS_PENDING';
const TVE_LOAD_OPTIONS_SUCCESS = 'tve/LOAD_OPTIONS_SUCCESS';
const TVE_LOAD_OPTIONS_ERROR = 'tve/LOAD_OPTIONS_ERROR';

const TVE_LOAD_MVPD_PENDING = 'tve/LOAD_MVPD_PENDING';
const TVE_LOAD_MVPD_SUCCESS = 'tve/LOAD_MVPD_SUCCESS';
const TVE_LOAD_MVPD_ERROR = 'tve/LOAD_MVPD_ERROR';

const TVE_LOAD_EPG_PENDING = 'tve/LOAD_EPG_PENDING';
const TVE_LOAD_EPG_SUCCESS = 'tve/LOAD_EPG_SUCCESS';
const TVE_LOAD_EPG_ERROR = 'tve/LOAD_EPG_ERROR';

const SET_AUTHENTICATION = 'tve/SET_AUTHENTICATION';
const SET_AUTHORIZATION = 'tve/SET_AUTHORIZATION';
const SET_TEMP_PASS_PROVIDER = 'tve/SET_TEMP_PASS_PROVIDER';
const SET_ACCOUNT_METADATA = 'tve/SET_ACCOUNT_METADATA';

const HIDE_PROVIDER_SELECT = 'tve/HIDE_PROVIDER_SELECT';
const SHOW_PROVIDER_SELECT = 'tve/SHOW_PROVIDER_SELECT';

const SHOW_PROVIDER_IFRAME = 'tve/SHOW_PROVIDER_IFRAME';
const HIDE_PROVIDER_IFRAME = 'tve/HIDE_PROVIDER_IFRAME';

// Actions
export const loadProvidersFeatured = (vertical) => api({
  endpoint: `tve/mvpd/${vertical}/featured`,
  types: [TVE_LOAD_FEATURED_PENDING, TVE_LOAD_FEATURED_SUCCESS, TVE_LOAD_FEATURED_ERROR],
  api: 'services',
});

export const loadProvidersOptions = (vertical) => api({
  endpoint: `tve/mvpd/${vertical}/options`,
  types: [TVE_LOAD_OPTIONS_PENDING, TVE_LOAD_OPTIONS_SUCCESS, TVE_LOAD_OPTIONS_ERROR],
  api: 'services',
});

export const loadProviderByID = (vertical, id) => api({
  endpoint: `tve/mvpd/${vertical}/id/${id}`,
  types: [TVE_LOAD_MVPD_PENDING, TVE_LOAD_MVPD_SUCCESS, TVE_LOAD_MVPD_ERROR],
  api: 'services',
});

export const loadScheduleAction = (vertical) => api({
  endpoint: `tve/schedule/${vertical}`,
  types: [
    TVE_LOAD_EPG_PENDING, TVE_LOAD_EPG_SUCCESS, TVE_LOAD_EPG_ERROR,
  ],
  api: 'services',
});

export const setAuthenticationAction = createAction(SET_AUTHENTICATION);
export const setAuthorizationAction = createAction(SET_AUTHORIZATION);
export const setTempPassProviderAction = createAction(SET_TEMP_PASS_PROVIDER);
export const setAccountMetadataAction = createAction(SET_ACCOUNT_METADATA);

export const hideProviderSelectAction = createAction(HIDE_PROVIDER_SELECT);
export const showProviderSelectAction = createAction(SHOW_PROVIDER_SELECT);

export const showProviderIFrameAction = createAction(SHOW_PROVIDER_IFRAME);
export const hideProviderIFrameAction = createAction(HIDE_PROVIDER_IFRAME);


// Reducers
const INITIAL_STATE = {
  authenticated: null,
  authorization: null,
  featured: [],
  loadingFeatured: false,
  loadingMVPD: false,
  loadingOptions: false,
  metadata: null,
  options: [],
  provider: null,
  providerIFrameVisible: false,
  providerSelectVisible: false,
  selected: null,
  // TempPass
  hasTempPass: false,
  tempPassProviderID: null,
  // Schedule
  epgCurrent: {},
  epgUpcoming: [],
  epgLoading: false,
};

export function TveReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SET_AUTHENTICATION: {
      const {
        tempPassProviderID,
        hasTempPass: hadTempPass,
      } = state;

      const provider = payload?.provider ?? (state.provider);
      const authenticated = payload?.authenticated ?? false;

      // Authenticated, update tempPass state
      let hasTempPass = hadTempPass;
      if (authenticated) {
        hasTempPass = tempPassProviderID === provider;
      }

      return {
        ...state,
        authenticated,
        hasTempPass,
        provider,
      };
    }

    case SET_AUTHORIZATION:
      return {
        ...state,
        authorization: payload?.token ?? null,
      };

    case SET_ACCOUNT_METADATA: {
      const key = Object.keys(payload)[0];
      return {
        ...state,
        metadata: {
          ...state.metadata,
          [key]: payload?.[key] ?? null,
        },
      };
    }

    case SET_TEMP_PASS_PROVIDER:
      return {
        ...state,
        tempPassProviderID: payload || null,
      };

    case HIDE_PROVIDER_SELECT:
      return {
        ...state,
        providerSelectVisible: false,
      };

    case SHOW_PROVIDER_SELECT:
      return {
        ...state,
        providerSelectVisible: true,
      };

    case HIDE_PROVIDER_IFRAME:
      return {
        ...state,
        providerIFrameVisible: false,
      };

    case SHOW_PROVIDER_IFRAME:
      return {
        ...state,
        providerIFrameVisible: true,
      };

    // FEATURED
    case TVE_LOAD_FEATURED_PENDING:
      return {
        ...state,
        loadingFeatured: true,
      };

    case TVE_LOAD_FEATURED_SUCCESS:
      return {
        ...state,
        featured: payload?.featured ?? [],
        loadingFeatured: false,
      };

    case TVE_LOAD_FEATURED_ERROR:
      return {
        ...state,
        loadingFeatured: false,
      };

    // OPTIONS
    case TVE_LOAD_OPTIONS_PENDING:
      return {
        ...state,
        loadingOptions: true,
      };

    case TVE_LOAD_OPTIONS_SUCCESS:
      return {
        ...state,
        options: payload?.options ?? [],
        loadingOptions: false,
      };

    case TVE_LOAD_OPTIONS_ERROR:
      return {
        ...state,
        loadingOptions: false,
      };

    // SPECIFIC
    case TVE_LOAD_MVPD_PENDING:
      return {
        ...state,
        loadingMVPD: true,
        selected: null,
      };

    case TVE_LOAD_MVPD_SUCCESS:
      return {
        ...state,
        loadingMVPD: false,
        selected: payload?.MVPD ?? [],
      };

    case TVE_LOAD_MVPD_ERROR:
      return {
        ...state,
        loadingMVPD: false,
        selected: null,
      };

    // SCHEDULE
    case TVE_LOAD_EPG_PENDING:
      return {
        ...state,
        epgLoading: true,
      };

    case TVE_LOAD_EPG_SUCCESS: {
      const now = (new Date()).getTime();
      const entries = payload?.schedule?.entries ?? [];
      return {
        ...state,
        epgCurrent: entries[0],
        epgUpcoming: entries.filter((entry) => entry.startTime >= now).slice(0, 12),
        epgLoading: false,
      };
    }

    case TVE_LOAD_EPG_ERROR:
      return {
        ...state,
        epgLoading: false,
      };

    default:
      return state;
  }
}
