import PropTypes from 'prop-types';

export default PropTypes.shape({
  content: PropTypes.string,
  picture: PropTypes.string,
  pictureLink: PropTypes.string,
  info: PropTypes.string,
  title: PropTypes.string,
  featuredAuthor: PropTypes.string,
  dek: PropTypes.string,
  byline: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  featuredAuthorImage: PropTypes.string,
});
