import api from 'lib/api';
import { createAction } from 'redux-actions';
import { publisherNameMap } from 'lib/vertical';
import { QUERY_LIMIT } from 'lib/waffleUtils';

export const AUTOMATED_WAFFLE_FETCH_PENDING = 'waffle/AUTOMATED_WAFFLE_FETCH_PENDING';
export const AUTOMATED_WAFFLE_FETCH_SUCCESS = 'waffle/AUTOMATED_WAFFLE_FETCH_SUCCESS';
export const AUTOMATED_WAFFLE_FETCH_ERROR = 'waffle/AUTOMATED_WAFFLE_FETCH_ERROR';

const INITIAL_STATE = {
  items: [],
  aggregation: null,
  loading: false,
  error: null,
  pagination: null,
  isInitialSet: false,
};

let initialFilters = null;

export function waffle(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case AUTOMATED_WAFFLE_FETCH_PENDING:
      return {
        ...state,
        loading: true,
      };

    case AUTOMATED_WAFFLE_FETCH_SUCCESS: {
      const { data: { search = null } = {} } = payload || {};
      if (!search || !payload) {
        return {
          ...state,
          loading: false,
          error: new Error('Fetching Waffle failed'),
        };
      }
      const { pagination, aggregation, items } = search;
      return {
        ...state,
        pagination,
        aggregation,
        items,
        error: null,
        loading: false,
        isInitialSet: true,
        // save filters created from Curator content so we don't have to generate them again
        initialFilters: initialFilters || state.initialFilters,
      };
    }

    case AUTOMATED_WAFFLE_FETCH_ERROR: {
      return {
        ...state,
        pagination: null,
        aggregations: null,
        items: [],
        error: null,
        loading: false,
        isInitialSet: false,
      };
    }

    default:
      return state;
  }
}

const setByPublisherType = (concatString, { value, name }) => {
  if (value) {
    let newString = concatString;
    newString += newString ? ' OR ' : '';
    newString += `type:${name}`;

    return newString;
  }
  return concatString;
};

// get values for the API filter
// filters are based off of the data put in the package from Curator
const getInitialFilters = (metadata, vertical) => {
  const {
    recipeContentType,
    articleContentType,
    slideshowContentType,
    productContentType,
    videoContentType,
    taxonomyTerms,
    excludeTaxonomyTerms,
  } = metadata;

  /* Create content type filters, depending on what flags are toggled on */

  let typeByVertical = '';
  let typeByPublisher = '';

  // product content types are currently not enabled in Curator; this is a placeholder for future use
  // to enable, add a new productContentType field in the Automated sub type definition
  // NOTE: this content type cannot be mixed with other content types due to the different rendering requirements
  if (productContentType) {
    // product content types can't be mixed with any other content types
    typeByPublisher = 'type:product';
  } else {
    const contentTypes = [
      {
        value: recipeContentType,
        name: 'recipe',
      },
      {
        value: videoContentType,
        name: 'video',
      },
      {
        value: slideshowContentType,
        name: 'slideshow',
      },
    ];

    typeByPublisher = contentTypes.reduce(setByPublisherType, typeByPublisher);

    if (articleContentType) typeByVertical = 'type:article';
  }

  // If no content type was chosen, don't fetch any content
  if (!typeByVertical && !typeByPublisher) return null;

  const filters = [];

  let typeFilter;

  // compbine type filters with vertical/publisher filter
  if (typeByVertical) typeFilter = `((${typeByVertical}) AND vertical:${vertical})`;

  if (typeByPublisher) {
    const publisherFilter = `((${typeByPublisher}) AND publisher:${publisherNameMap[vertical]})`;
    typeFilter = typeFilter ? `(${typeFilter} OR ${publisherFilter})` : publisherFilter;
  }

  filters.push(typeFilter);

  /* create filter for the taxonomy selected in Curator */
  if (taxonomyTerms?.length) {
    const terms = taxonomyTerms.filter((t) => !!t.taxonomyTerm).map((t) => `taxonomy:${t.taxonomyTerm}`);
    if (terms.length) filters.push(`(${terms.join(' OR ')})`);
  }

  /* create filter for the taxonomy to exclude selected in Curator */
  if (excludeTaxonomyTerms?.length) {
    const terms = excludeTaxonomyTerms.filter((t) => !!t.excludeTaxonomyTerm).map((t) => `NOT taxonomy:${t.excludeTaxonomyTerm}`);
    if (terms.length) filters.push(`(${terms.join(' OR ')})`);
  }

  return filters;
};

export const loadAutomatedWaffle = ({
  vertical,
  metadata = {},
  searchFilters = null,
  page = 1,
  queryLimit = QUERY_LIMIT,
  initialFilters: baseFilters,
}) => {
  // set initial filters as defined by the options set in Curator
  initialFilters = baseFilters || getInitialFilters(metadata, vertical);

  // return empty values if no valid filters set from Curator
  if (!initialFilters) return createAction(AUTOMATED_WAFFLE_FETCH_ERROR);

  const filters = [...initialFilters];

  // add additional taxonomy filters if selected by user
  if (searchFilters && searchFilters.length) {
    searchFilters.forEach((f) => {
      filters.push(`(taxonomy:${f.join(' OR taxonomy:')})`);
    });
  }

  const endpoint = `/waffle/automated?filters=${filters.join(' AND ')}&size=${queryLimit}&page=${page}`;

  return api({
    endpoint,
    types: [
      AUTOMATED_WAFFLE_FETCH_PENDING, AUTOMATED_WAFFLE_FETCH_SUCCESS, AUTOMATED_WAFFLE_FETCH_ERROR,
    ],
  });
};
