import PropTypes from 'prop-types';

export default {
  packageIndex: PropTypes.number,
  topOfPagePackages: PropTypes.bool,
  pkgTitleColorIndex: PropTypes.number,

  // Zone context
  zone: PropTypes.number,
  zonePackageTypes: PropTypes.arrayOf(PropTypes.string),

  // Zone rail context
  isRailAdjacent: PropTypes.bool,
  railContext: PropTypes.oneOf(['left', 'right']),
};
